import { MapView } from "@avinet/adaptive-ui-maps";
import Zoom from "@avinet/adaptive-ui-maps/control/Zoom";
import ZoomToExtent from "@avinet/adaptive-ui-maps/control/ZoomToExtent";
import XyzLayer from "@avinet/adaptive-ui-maps/layer/XyzLayer";
import VectorLayer from "@avinet/adaptive-ui-maps/vector/VectorLayer";
import VectorSource from "@avinet/adaptive-ui-maps/vector/source/VectorSource";
import Feature from "ol/Feature";
import Icon from "@avinet/adaptive-ui-core/ui/Icon";
import {
  Icon as OlIcon,
  Style,
  Circle,
  Fill,
  Stroke,
  Text,
  RegularShape,
} from "ol/style";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { useMapContext } from "@avinet/adaptive-ui-maps";
import Point from "ol/geom/Point";
import LineString from "ol/geom/LineString";
import { MapDestinationIcon } from "../../assets/icons/MapDestinationIcon";
import "./MapWrapper.scss";
import { SelectedMapDestinationIcon } from "../../assets/icons/SelectedMapDestination";
import { fromExtent as polygonFromExtent } from "ol/geom/Polygon";
import { MapBrowserEvent } from "ol";
import LOCAL_STORAGE from "../../constants/LocalStorage";
import MapLayerList from "../map-layer-list/MapLayerList";
import { map_layers } from "../../constants/MapLayers";
import { FeatureLike } from "ol/Feature";
import {
  DRAWING_OBJECT_TYPES,
  MAP_OPTIONS,
  STATUSES,
} from "../../constants/Constants";
import { IOrder, ISymbol, OrderObject } from "../../utils/data-types";
import { useDrawing } from "../../context/drawing-context/DrawingContext";
import { useVectorLayer } from "../../context/vector-layer-context/VectorLayerContext";
import WKT from "ol/format/WKT";
import { projection } from "../../constants/Projection";
import { useMapBoxData } from "../../context/map-box-data-context/MapBoxDataContext";
import MapproxyWMTSLayer from "./MapproxyWMTSLayer";

export const MapWrapper = ({
  featuresWKT1,
  featuresWKT2,
  selectedId,
  defaultExtent,
  selectedOption,
  onFeatureMapClick,
  sendExtentToParent,
  sendZoomToParent,
  sendPositionToParent,
  order,
}: {
  children: ReactNode;
  featuresWKT1?: Feature[];
  featuresWKT2?: Feature[];
  selectedId: number | undefined;
  defaultExtent: number[];
  selectedOption: string;
  onFeatureMapClick: (id: number | undefined) => void;
  sendExtentToParent?: (extent: number[]) => void;
  sendZoomToParent?: (zoom: number) => void;
  sendPositionToParent?: (position: Point) => void;
  order?: IOrder;
}) => {
  const [userPosition, setUserPosition] = useState<Point>(
    new Point([]) as Point
  );
  const [pointClicked, setPointClicked] = useState<Point>(
    new Point([]) as Point
  );
  const [lineCoordinates, setLineCoordinates] = useState<number[][]>([]);
  const [isPointSelected, setIsPointSelected] = useState<boolean>(false);
  const [isLineDrawn, setIsLineDrawn] = useState<boolean>();
  const [lineFeature, setLineFeature] = useState<Feature | null>(null);
  const [trackPosition, setTrackPosition] = useState<boolean>(false);
  const [extentState, setExtentState] = useState(defaultExtent);
  const [isShowingMapLayers, setIsShowingMapLayers] = useState<boolean>(false);
  const [selectedMapOption, setSelectedMapOption] = useState<string>();
  const [mapLayerFeatures, setMapLayerFeatures] = useState<Feature[]>([]);

  const { setCenterAndZoom, view, map } = useMapContext();
  const { setMapBoxData } = useMapBoxData();
  const {
    isDrawMode,
    isMovingPoint,
    setHasDrawn,
    setDrawnObject,
    drawnObjectType,
  } = useDrawing();
  const {
    activeFeatures,
    setSelectedDigithemeuuid,
    setSelectedTblId,
    vectorLayerSymbols,
  } = useVectorLayer();

  useEffect(() => {
    setIsPointSelected(
      pointClicked.getCoordinates().length > 0 && isDrawMode === true
    );
    setIsLineDrawn(lineCoordinates.length > 1);
    setHasDrawn((isPointSelected || isLineDrawn) ?? false);
  }, [
    setHasDrawn,
    isDrawMode,
    isLineDrawn,
    isPointSelected,
    lineCoordinates.length,
    pointClicked,
    isMovingPoint,
  ]);

  useEffect(() => {
    if (isPointSelected) {
      setDrawnObject(pointClicked);
    }
  }, [setDrawnObject, isPointSelected, pointClicked]);

  const toggleMapLayerList = useCallback(() => {
    setIsShowingMapLayers((prev) => !prev);
  }, []);

  const liftPosition = useCallback(
    (position: Point) => {
      sendPositionToParent?.(position);
    },
    [sendPositionToParent]
  );

  useEffect(() => {
    if (!userPosition) return;
    liftPosition(userPosition);
  }, [liftPosition, userPosition]);

  const liftZoom = useCallback(
    (zoom: number) => {
      sendZoomToParent?.(zoom);
    },
    [sendZoomToParent]
  );

  const zoom = view.getZoom();

  useEffect(() => {
    const handleMoveEnd = () => {
      const zoom = view.getZoom();
      if (!zoom) return;
      liftZoom(zoom);
    };

    map.on("moveend", handleMoveEnd);

    return () => {
      map.un("moveend", handleMoveEnd);
    };
  }, [liftZoom, map, selectedMapOption, view, zoom]);

  const liftExtent = useCallback(
    (extent: number[]) => {
      sendExtentToParent?.(extent);
    },
    [sendExtentToParent]
  );

  useEffect(() => {
    const handleMoveEnd = () => {
      const newExtent = view.calculateExtent();
      liftExtent(newExtent);
    };

    map.on("moveend", handleMoveEnd);

    return () => {
      map.un("moveend", handleMoveEnd);
    };
  }, [map, view, liftExtent, selectedMapOption]);

  const pinchRotate = map.getInteractions().getArray()[3];

  pinchRotate.setActive(false);

  useEffect(() => {
    const handleViewChange = () => {
      const extent = view.calculateExtent();
      setExtentState(extent);
    };

    // Attach event listener for view changes
    view.on("change:center", handleViewChange);

    // Cleanup event listener on component unmount
    return () => {
      view.un("change:center", handleViewChange);
    };
  }, [view]);

  useEffect(() => {
    localStorage.setItem(
      LOCAL_STORAGE.MAP_EXTENT,
      JSON.stringify(extentState) ?? "[]"
    );
  }, [extentState]);

  const handleMapClick = useCallback(
    (evt: MapBrowserEvent<UIEvent>) => {
      if (!map.hasFeatureAtPixel(evt.pixel)) {
        onFeatureMapClick(undefined);
        setSelectedDigithemeuuid("");
        setSelectedTblId(undefined);
        setMapBoxData(undefined);
        return;
      }

      map.forEachFeatureAtPixel(evt.pixel, (feature) => {
        if (feature.getProperties().isMapLayer) {
          setSelectedDigithemeuuid(feature.get("digithemeuuid"));
          setSelectedTblId(feature.getId() as number);
        }
        onFeatureMapClick(feature.getId() as number);
      });
    },
    [
      map,
      onFeatureMapClick,
      setMapBoxData,
      setSelectedDigithemeuuid,
      setSelectedTblId,
    ]
  );

  const newPoint = useCallback((evt: MapBrowserEvent<UIEvent>) => {
    if (!evt.coordinate) return;
    const point = new Point(evt.coordinate);
    setPointClicked(point);
  }, []);

  const handleLineClick = useCallback(
    (evt: MapBrowserEvent<UIEvent>) => {
      if (!evt.coordinate) return;

      setLineCoordinates((prev) => {
        const newCoordinates = [...prev, evt.coordinate];

        // Create or update the LineString with the new coordinates
        const lineString = new LineString(newCoordinates);
        setDrawnObject(lineString);

        if (lineFeature) {
          // If a line feature already exists, update its geometry
          lineFeature.setGeometry(lineString);
        } else {
          // Create a new feature from the LineString and add it to the state
          const newLineFeature = new Feature({
            geometry: lineString,
          });
          setLineFeature(newLineFeature);
        }

        return newCoordinates;
      });
    },
    [setDrawnObject, lineFeature]
  );

  useEffect(() => {
    setPointClicked(new Point([]));
    setLineCoordinates([]);
    setLineFeature(null);
  }, [isDrawMode]);

  useEffect(() => {
    if (drawnObjectType === DRAWING_OBJECT_TYPES.POINT && isDrawMode) {
      map.on("click", newPoint);
      return () => {
        map.un("click", newPoint);
      };
    } else if (drawnObjectType === DRAWING_OBJECT_TYPES.LINE && isDrawMode) {
      map.on("click", handleLineClick);
      return () => {
        map.un("click", handleLineClick);
      };
    } else {
      map.on("click", handleMapClick);
      return () => {
        map.un("click", handleMapClick);
      };
    }
  }, [
    map,
    newPoint,
    handleMapClick,
    drawnObjectType,
    handleLineClick,
    isDrawMode,
  ]);

  const newPointFeature = useMemo(() => {
    return new Feature({
      geometry: pointClicked,
    });
  }, [pointClicked]);

  useEffect(() => {
    const e = defaultExtent ?? view.getProjection()?.getExtent();
    if (e) view.fitInternal(polygonFromExtent(e));
  }, [defaultExtent, view]);

  const [position, setPosition] = useState<GeolocationPosition | null>(null);

  const positionStyle = () => {
    return new Style({
      image: new Circle({
        radius: 6,
        fill: new Fill({
          color: "blue",
        }),
        stroke: new Stroke({
          color: "black",
          width: 1,
        }),
      }),
    });
  };

  const lineStyle = () => {
    return new Style({
      stroke: new Stroke({
        color: "blue",
        width: 3,
      }),
    });
  };

  useEffect(() => {
    const watchId = navigator.geolocation.watchPosition(
      (pos) => setPosition(pos),
      (err) => console.error(err),
      {
        enableHighAccuracy: true,
        maximumAge: 5000,
      }
    );

    return () => navigator.geolocation.clearWatch(watchId);
  }, []);

  useEffect(() => {
    const coords = [position?.coords.longitude, position?.coords.latitude];
    if (coords) {
      const point = new Point(coords as [number, number]);
      point.transform("EPSG:4326", "EPSG:3857");
      setUserPosition(point);
    }
  }, [position]);

  const positionFeature = useMemo(() => {
    return new Feature({
      geometry: userPosition,
    });
  }, [userPosition]);

  const selectedFeatureWKT1 = useMemo(
    () => featuresWKT1?.find((feature) => feature.getId() === selectedId),
    [featuresWKT1, selectedId]
  );

  const selectedFeatureWKT2 = useMemo(
    () => featuresWKT2?.find((feature) => feature.getId() === selectedId),
    [featuresWKT2, selectedId]
  );

  const createShapeStyle = (
    type: string,
    fillColor: string,
    text?: string
  ): Style => {
    const commonProperties = {
      fill: new Fill({ color: fillColor }),
      stroke: new Stroke({ color: "white", width: 1 }),
    };

    let image;
    switch (type) {
      case "circle":
        image = new Circle({ radius: 7, ...commonProperties });
        break;
      case "square":
        image = new RegularShape({
          ...commonProperties,
          points: 4,
          radius: 10,
          angle: Math.PI / 4,
        });
        break;
      case "triangle":
        image = new RegularShape({
          ...commonProperties,
          points: 3,
          radius: 10,
          rotation: Math.PI / 4,
          angle: 0,
        });
        break;
      case "star":
        image = new RegularShape({
          ...commonProperties,
          points: 5,
          radius: 10,
          radius2: 4,
          angle: 0,
        });
        break;
    }

    return new Style({
      image,
      text: text
        ? new Text({
            font: "bold 12px sans-serif",
            rotation: 0,
            rotateWithView: false,
            scale: 1,
            text,
            offsetY: -15,
          })
        : undefined,
    });
  };

  const getVectorLayerSymbol = (feature: FeatureLike): ISymbol | undefined => {
    const digithemeuuid = feature.getProperties().digithemeuuid;
    return vectorLayerSymbols.find(
      (symbol) => symbol.digi_theme_uuid === digithemeuuid
    );
  };

  const regularShapesStyle = (feature: FeatureLike): Style | undefined => {
    const vectorLayerSymbol = getVectorLayerSymbol(feature);
    if (!vectorLayerSymbol?.classes) return;

    const featureStatus = feature.getProperties().properties.status;
    const isClassitem = vectorLayerSymbol.classitem !== "";
    const selectedClass = isClassitem
      ? vectorLayerSymbol.classes.find((cls) => cls.filter === featureStatus)
      : vectorLayerSymbol.classes[0];

    return selectedClass
      ? createShapeStyle(selectedClass.type, selectedClass.fill)
      : undefined;
  };

  const selectedRegularShapesStyle = (
    feature: FeatureLike
  ): Style | undefined => {
    const vectorLayerSymbol = getVectorLayerSymbol(feature);
    if (!vectorLayerSymbol?.classes) return;

    const labelKey = vectorLayerSymbol.labelitem;
    const label = labelKey
      ? String(feature.getProperties().properties[labelKey])
      : undefined;

    return createShapeStyle(vectorLayerSymbol.classes[0].type, "black", label);
  };

  const createStyle = (
    feature: FeatureLike,
    type: "selected" | "custom",
    status?: string
  ): Style | undefined => {
    const geometryType = feature.getGeometry()?.getType();

    if (geometryType === "Point") {
      let iconComponent;
      let fillColor = "#5157BD";

      if (type === "selected") {
        iconComponent = <SelectedMapDestinationIcon />;
      } else {
        switch (status) {
          case STATUSES.COMPLETED:
            fillColor = "#079455";
            break;
          case STATUSES.NOT_COMPLETED:
            fillColor = "#f79009";
            break;
          case STATUSES.ORDERED:
            fillColor = "#155eef";
            break;
        }
        iconComponent = <MapDestinationIcon fill={fillColor} />;
      }

      return new Style({
        image: new OlIcon({
          anchor: [0.5, 1],
          src: `data:image/svg+xml;utf8,${encodeURIComponent(renderToStaticMarkup(iconComponent))}`,
        }),
        text:
          type === "selected"
            ? new Text({
                font: "bold 12px sans-serif",
                rotation: 0,
                rotateWithView: false,
                scale: 1,
                text: feature.get("name")?.split(" ").slice(1).join(" ") || "",
                offsetY: -40,
              })
            : undefined,
      });
    } else if (geometryType === "LineString") {
      return new Style({
        stroke: new Stroke({
          color: type === "selected" ? "black" : "blue",
          width: 3,
        }),
      });
    }
  };

  const createSelectedStyle = (feature: FeatureLike): Style | undefined => {
    return createStyle(feature, "selected");
  };

  const createCustomStyle = (feature: FeatureLike): Style | undefined => {
    const id = feature.getId();
    const orderObject = order?.orderobject.find(
      (o: OrderObject) => o.id === id
    );
    return createStyle(feature, "custom", orderObject?.status);
  };

  useEffect(() => {
    setSelectedMapOption(selectedOption);
  }, [selectedOption]);

  const handleSetTrackPosition = useCallback(() => {
    setTrackPosition((prev) => !prev);
  }, []);

  useEffect(() => {
    if (trackPosition) setCenterAndZoom(userPosition.getCoordinates());
  }, [trackPosition, setCenterAndZoom, userPosition]);

  const renderVectors = useCallback(() => {
    if (!activeFeatures || activeFeatures.length === 0) return [];
    const listOfVectorLayerFeatures = activeFeatures
      .map((task) => {
        return task.features.map((feature) => {
          const wkt = new WKT();
          const geometry = wkt.readGeometry(feature.geom);
          if (geometry) geometry.transform(projection, "EPSG:3857");
          const newFeature = new Feature({
            geometry,
            properties: feature,
          });
          newFeature.setId(feature.id);
          newFeature.set("digithemeuuid", task.digi_theme_uuid);
          newFeature.set("isMapLayer", true);
          return newFeature;
        });
      })
      .flat();
    return listOfVectorLayerFeatures;
  }, [activeFeatures]);

  useEffect(() => {
    setMapLayerFeatures(renderVectors());
  }, [renderVectors]);

  const selectedFeat = useMemo(
    () => mapLayerFeatures.find((feat) => feat.getId() === selectedId),
    [mapLayerFeatures, selectedId]
  );

  useEffect(() => {
    if (
      (selectedFeatureWKT1 || selectedFeatureWKT2 || selectedFeat) &&
      selectedMapOption === MAP_OPTIONS.ZOOM_TO
    ) {
      setTrackPosition(false);
      if (selectedFeatureWKT2?.getGeometry()?.getType() === "LineString") {
        const geometry = selectedFeatureWKT2?.getGeometry() as LineString;
        const coords = geometry.getCoordinates();
        if (coords) {
          // fit the view to the extent of the line
          const line = new LineString(coords);
          const extent = line.getExtent();
          view.fit(extent, { padding: [100, 100, 100, 100] });
        }
      } else if (selectedFeatureWKT1?.getGeometry()?.getType() === "Point") {
        const geometry = selectedFeatureWKT1?.getGeometry() as Point;
        const coords = geometry.getCoordinates();
        if (coords) {
          setCenterAndZoom(coords, 17);
        }
      } else {
        const geometry = selectedFeat?.getGeometry() as Point;
        const coords = geometry.getCoordinates();
        if (coords) {
          setCenterAndZoom(coords, 17);
        }
      }
    }
  }, [
    setCenterAndZoom,
    view,
    selectedMapOption,
    selectedFeatureWKT1,
    selectedFeatureWKT2,
    selectedFeat,
  ]);

  const offlineConfig = {
    url: "https://mapproxy.avinet.no/service?",
    layer: "osm_webmercator",
    tilematrixset: "osm_grid",
    levels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
    bbox: [569685, 8523722, 665995, 8621103],
  };

  return (
    <>
      <MapView>
        <button
          className={`btn position ${trackPosition ? "active" : ""}`}
          onClick={handleSetTrackPosition}
        >
          <Icon name="myLocation" />
        </button>
        <button
          className={`btn layers ${isShowingMapLayers ? "active" : ""}`}
          onClick={toggleMapLayerList}
        >
          <Icon name="mapLayer" />
        </button>
        <Zoom />
        <ZoomToExtent extent={defaultExtent} />
      </MapView>
      {isShowingMapLayers && (
        <MapLayerList
          showingMapLayers={isShowingMapLayers}
          onToggleMapLayerList={toggleMapLayerList}
        />
      )}
      <XyzLayer
        url={
          localStorage.getItem(LOCAL_STORAGE.SELECTED_MAP_URL) ??
          map_layers[0].value
        }
        zIndex={1}
      />
      <MapproxyWMTSLayer
        url={offlineConfig.url}
        layerName={offlineConfig.layer}
        tilematrixset={offlineConfig.tilematrixset}
        zIndex={0}
      />
      <VectorLayer zIndex={21} style={createCustomStyle} maxZoom={12}>
        <VectorSource features={featuresWKT1 ?? []} />
      </VectorLayer>
      <VectorLayer zIndex={21} style={createCustomStyle} minZoom={12}>
        <VectorSource features={featuresWKT2 ?? []} />
      </VectorLayer>
      <VectorLayer zIndex={22} style={createSelectedStyle} maxZoom={12}>
        <VectorSource
          features={selectedFeatureWKT1 ? [selectedFeatureWKT1] : []}
        />
      </VectorLayer>
      <VectorLayer zIndex={22} style={createSelectedStyle} minZoom={12}>
        <VectorSource
          features={selectedFeatureWKT2 ? [selectedFeatureWKT2] : []}
        />
      </VectorLayer>
      <VectorLayer zIndex={22} style={selectedRegularShapesStyle}>
        <VectorSource features={selectedFeat ? [selectedFeat] : []} />
      </VectorLayer>
      <VectorLayer zIndex={23} style={positionStyle}>
        <VectorSource features={positionFeature ? [positionFeature] : []} />
      </VectorLayer>
      {isDrawMode && (
        <VectorLayer zIndex={24} style={createSelectedStyle}>
          <VectorSource features={newPointFeature ? [newPointFeature] : []} />
        </VectorLayer>
      )}
      {isDrawMode && (
        <VectorLayer zIndex={25} style={lineStyle}>
          <VectorSource features={lineFeature ? [lineFeature] : []} />
        </VectorLayer>
      )}
      <VectorLayer zIndex={21} style={regularShapesStyle}>
        <VectorSource features={renderVectors()} />
      </VectorLayer>
    </>
  );
};
