import { Text } from "../text/Text";

// import "./ConfirmationModalContent.scss";
import { t } from "i18next";
import { Icon } from "../icon/Icon";
import { IOrder } from "../../utils/data-types";
import { useCallback, useContext } from "react";
import { DEFAULT_API_CONFIG } from "../../api/api-config";
import { UserContext } from "../../context/user-context/UserContext";
import superagent from "superagent";
import { useParams } from "react-router-dom";
import { getResponse, setResponse } from "../../utils/indexedDb";

export const DesyncedOrderModalContent = ({
  onclose,
  onSyncOrder,
}: {
  onclose: () => void;
  onSyncOrder: () => void;
}) => {
  const { token } = useContext(UserContext);
  const { orderId: orderId, selectedFilter: selectedFilter } = useParams();

  const syncOrder = useCallback(async () => {
    try {
      const response = await superagent
        .get(
          DEFAULT_API_CONFIG.url +
            `/diaryorder/order/getOrderWithOrderObjects?order_id=${orderId}`
        )
        .set("Accept", "application/json")
        .set("gm_session_id", token ?? "");

      if (selectedFilter) {
        const indexedDBOrders = await getResponse(selectedFilter);
        if (indexedDBOrders) {
          const updatedOrders = indexedDBOrders.map(
            (indexedDBOrder: IOrder) => {
              if (indexedDBOrder.id === Number(orderId)) {
                return response.body[0];
              }
              return indexedDBOrder;
            }
          );
          await setResponse(selectedFilter, updatedOrders);
        }
      }
    } catch (error) {
      console.error("Error syncing order:", error);
    }
  }, [orderId, selectedFilter, token]);

  const handleDesyncedOrder = useCallback(async () => {
    onclose();
    await syncOrder();
    onSyncOrder();
  }, [onclose, syncOrder, onSyncOrder]);

  return (
    <div className="confirmation-modal-content">
      <div className="confirmation-modal-content__text">
        <Text text={t("pages.orderInfo.desyncedOrderModal.text")} size="xs" />
      </div>
      <div className="confirmation-modal-content__buttons">
        <button className="btn" onClick={handleDesyncedOrder}>
          <Icon name="arrowSync" />
          {t("pages.orderInfo.desyncedOrderModal.sync")}
        </button>
        <button className="btn-light" onClick={onclose}>
          <Icon name="cross" />
          {t("common.cancel")}
        </button>
      </div>
    </div>
  );
};
