import {
  createContext,
  ReactNode,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
} from "react";
import superagent from "superagent";
import {
  IMapLayerObject,
  ISymbol,
  OfflineVectorFeat,
} from "../../utils/data-types";
import { getResponse, setResponse } from "../../utils/indexedDb";

interface VectorLayerContextType {
  otherVectorLayers: IMapLayerObject[] | undefined;
  setOtherVectorLayers: (
    vectorRequestData: IMapLayerObject[] | undefined
  ) => void;
  activeFeatures: OfflineVectorFeat[];
  setActiveFeatures: Dispatch<SetStateAction<OfflineVectorFeat[]>>;
  loadingFeatures: Set<string>;
  setLoadingFeatures: Dispatch<SetStateAction<Set<string>>>;
  handleCheckboxChange: (layer: IMapLayerObject | null) => void;
  selectedDigithemeuuid: string;
  setSelectedDigithemeuuid: Dispatch<SetStateAction<string>>;
  selectedTblId: number | undefined;
  setSelectedTblId: Dispatch<SetStateAction<number | undefined>>;
  vectorLayerSymbols: ISymbol[];
  setVectorLayerSymbols: Dispatch<SetStateAction<ISymbol[]>>;
}

const VectorLayerContext = createContext<VectorLayerContextType | undefined>(
  undefined
);

interface VectorLayerProviderProps {
  children: ReactNode;
}

export const VectorLayerProvider = ({ children }: VectorLayerProviderProps) => {
  const [otherVectorLayers, setOtherVectorLayers] = useState<
    IMapLayerObject[] | undefined
  >(undefined);
  const [activeFeatures, setActiveFeatures] = useState<OfflineVectorFeat[]>([]);
  const [loadingFeatures, setLoadingFeatures] = useState<Set<string>>(
    new Set()
  );
  const [vectorLayerSymbols, setVectorLayerSymbols] = useState<ISymbol[]>([]);
  const [selectedDigithemeuuid, setSelectedDigithemeuuid] =
    useState<string>("");
  const [selectedTblId, setSelectedTblId] = useState<number>();

  const handleCheckboxChange = useCallback(
    async (layer: IMapLayerObject | null) => {
      if (layer === null) {
        setActiveFeatures([]);
        return;
      }

      const activeFeature = activeFeatures.find(
        (f) => f.layerName === layer.name
      );

      if (!activeFeature) {
        setLoadingFeatures((prev) => new Set(prev).add(layer.name));

        // Define existingFeature outside try block
        let existingFeature: OfflineVectorFeat = {} as OfflineVectorFeat;

        try {
          // Try to get cached data from IndexedDB first
          const cachedData = await getResponse("vectorFeatures");
          if (Array.isArray(cachedData)) {
            existingFeature = cachedData.find(
              (f) => f.layerName === layer.name
            );
          }

          if (!navigator.onLine && existingFeature) {
            // If offline and data exists, use cached data
            setActiveFeatures((prevActiveFeatures) => [
              ...prevActiveFeatures,
              existingFeature,
            ]);
            return;
          }

          // If online, fetch fresh data
          const data = await superagent
            .get("https://ngirdo.avinet.no" + layer.url)
            .set("Accept", "application/json");

          const newFeature = {
            features: data.body.features,
            total: data.body.features.length,
            layerName: layer.name,
            digi_theme_uuid: layer.digi_theme_uuid,
          };

          // Update state with new data
          setActiveFeatures((prevActiveFeatures) => [
            ...prevActiveFeatures,
            newFeature,
          ]);

          // Update IndexedDB with the latest data
          let updatedData;
          if (Array.isArray(cachedData)) {
            updatedData = cachedData.filter((f) => f.layerName !== layer.name);
            updatedData.push(newFeature);
          } else {
            updatedData = [newFeature];
          }

          setResponse("vectorFeatures", updatedData);
        } catch (error) {
          console.error("Error fetching log posts:", error);

          // If offline and cached data exists, use it
          if (existingFeature) {
            setActiveFeatures((prevActiveFeatures) => [
              ...prevActiveFeatures,
              existingFeature,
            ]);
          }
        } finally {
          setLoadingFeatures((prev) => {
            const updatedSet = new Set(prev);
            updatedSet.delete(layer.name);
            return updatedSet;
          });
        }
      } else {
        // Uncheck layer - Remove only from state, NOT IndexedDB
        setActiveFeatures((prevActiveFeatures) =>
          prevActiveFeatures.filter((f) => f.layerName !== layer.name)
        );
      }
    },
    [activeFeatures]
  );

  return (
    <VectorLayerContext.Provider
      value={{
        otherVectorLayers,
        setOtherVectorLayers,
        activeFeatures,
        setActiveFeatures,
        loadingFeatures,
        setLoadingFeatures,
        handleCheckboxChange,
        selectedDigithemeuuid,
        setSelectedDigithemeuuid,
        selectedTblId,
        setSelectedTblId,
        vectorLayerSymbols,
        setVectorLayerSymbols,
      }}
    >
      {children}
    </VectorLayerContext.Provider>
  );
};

export const useVectorLayer = () => {
  const context = useContext(VectorLayerContext);
  if (!context) {
    throw new Error("useVectorLayer must be used within a VectorLayerProvider");
  }
  return context;
};
