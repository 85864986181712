import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Dropdown } from "../dropdown/Dropdown";
import { t } from "i18next";
import LOCAL_STORAGE from "../../constants/LocalStorage";
import "./DrivingModalContent.scss";
import superagent from "superagent";
import { DEFAULT_API_CONFIG } from "../../api/api-config";
import { useDriving } from "../../context/driving-context/DrivingContext";
import { Text } from "../text/Text";
import { getResponse, setResponse } from "../../utils/indexedDb";
import { IDrivingRequest } from "../../utils/data-types";
import { useMobileView } from "../../context/mobile-view-context/MobileViewContext";
import { UserContext } from "../../context/user-context/UserContext";

interface DrivingModalContentProps {
  onClose: () => void;
}

export const DrivingModalContent = ({ onClose }: DrivingModalContentProps) => {
  const { isDriving } = useDriving();
  const { isMobileView } = useMobileView();
  const { token } = useContext(UserContext);

  const generateUuid = useMemo(() => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }, []);

  const [selectedKilos, setSelectedKilos] = useState<{
    [key: string]: number;
  }>({
    rest_ppp: 0,
    bio: 0,
    total_final_weight: 0,
    after_rest_ppp: 0,
    after_bio: 0,
  });

  const handleKilosOptionChange = useCallback((key: string, value: number) => {
    setSelectedKilos((prevKilos: { [key: string]: number }) => ({
      ...prevKilos,
      [key]: value,
    }));
  }, []);

  const [afterRestPPPDisabled, setAfterRestPPPDisabled] =
    useState<boolean>(false);
  const [afterBioDisabled, setAfterBioDisabled] = useState<boolean>(false);

  useEffect(() => {
    setAfterBioDisabled(selectedKilos.after_rest_ppp > 0);
    setAfterRestPPPDisabled(selectedKilos.after_bio > 0);
  }, [selectedKilos.after_bio, selectedKilos.after_rest_ppp]);

  useEffect(() => {
    if (isNaN(selectedKilos.rest_ppp)) {
      setSelectedKilos((prevKilos) => ({
        ...prevKilos,
        rest_ppp: 0,
      }));
    }
    if (isNaN(selectedKilos.bio)) {
      setSelectedKilos((prevKilos) => ({
        ...prevKilos,
        bio: 0,
      }));
    }
    if (isNaN(selectedKilos.total_final_weight)) {
      setSelectedKilos((prevKilos) => ({
        ...prevKilos,
        total_final_weight: 0,
      }));
    }
    if (isNaN(selectedKilos.after_rest_ppp)) {
      setSelectedKilos((prevKilos) => ({
        ...prevKilos,
        after_rest_ppp: 0,
      }));
    }
    if (isNaN(selectedKilos.after_bio)) {
      setSelectedKilos((prevKilos) => ({
        ...prevKilos,
        after_bio: 0,
      }));
    }
  }, [selectedKilos]);

  const startWeight = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE.STARTWEIGHT) || "{}"
  );

  const sumRestPPPAndBio = useMemo(() => {
    const totalWeightArray = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE.TOTAL_WEIGHT) || "[]"
    );

    let totalRestPPP = 0;
    let totalBio = 0;

    for (let i = 0; i < totalWeightArray.length; i++) {
      totalRestPPP += parseFloat(totalWeightArray[i].restPPP) || 0;
      totalBio += parseFloat(totalWeightArray[i].bio) || 0;
    }

    return {
      totalRestPPP: totalRestPPP,
      totalBio: totalBio,
      startRestPPP: startWeight.rest_ppp,
      startBio: startWeight.bio,
    };
  }, [startWeight.bio, startWeight.rest_ppp]);

  const sumRestPPP =
    sumRestPPPAndBio.totalRestPPP + sumRestPPPAndBio.startRestPPP;
  const sumBio = sumRestPPPAndBio.totalBio + sumRestPPPAndBio.startBio;

  const shouldDisableStartDrivingButton = useMemo<boolean>(() => {
    if (isNaN(selectedKilos.rest_ppp) || isNaN(selectedKilos.bio)) return true;
    return selectedKilos.rest_ppp < 0 || selectedKilos.bio < 0;
  }, [selectedKilos]);

  const end_default = useMemo<number>(() => {
    if (selectedKilos.after_rest_ppp > selectedKilos.after_bio) {
      return selectedKilos.total_final_weight - selectedKilos.after_rest_ppp;
    } else {
      return selectedKilos.total_final_weight - selectedKilos.after_bio;
    }
  }, [
    selectedKilos.after_bio,
    selectedKilos.after_rest_ppp,
    selectedKilos.total_final_weight,
  ]);

  const end_extra = useMemo<number>(() => {
    if (selectedKilos.after_rest_ppp > selectedKilos.after_bio) {
      return selectedKilos.after_rest_ppp - selectedKilos.after_bio;
    } else {
      return selectedKilos.after_bio - selectedKilos.after_rest_ppp;
    }
  }, [selectedKilos.after_bio, selectedKilos.after_rest_ppp]);

  const shouldDisableEndDrivingButton = useMemo<boolean>(() => {
    if (
      selectedKilos.after_rest_ppp < 0 ||
      selectedKilos.after_bio < 0 ||
      selectedKilos.total_final_weight < 0
    )
      return true;
    if (selectedKilos.total_final_weight < sumRestPPP + sumBio) return true;
    if (isNaN(end_default) || isNaN(end_extra)) return true;
    if (end_default < sumRestPPP || end_extra < sumBio) return true;
    return (
      selectedKilos.after_rest_ppp > selectedKilos.total_final_weight ||
      selectedKilos.after_bio > selectedKilos.total_final_weight
    );
  }, [
    end_default,
    end_extra,
    selectedKilos.after_bio,
    selectedKilos.after_rest_ppp,
    selectedKilos.total_final_weight,
    sumBio,
    sumRestPPP,
  ]);

  const startWeightRequest = useMemo<IDrivingRequest>(() => {
    return {
      job_uuid: generateUuid,
      start_default: selectedKilos.rest_ppp,
      start_extra: selectedKilos.bio,
      end_default: 0,
      end_extra: 0,
    };
  }, [generateUuid, selectedKilos.bio, selectedKilos.rest_ppp]);

  const endWeightRequest = useMemo<IDrivingRequest>(() => {
    return {
      job_uuid: localStorage.getItem(LOCAL_STORAGE.JOB_UUID),
      start_default: startWeight.rest_ppp,
      start_extra: startWeight.bio,
      end_default: end_default,
      end_extra: end_extra,
    };
  }, [end_default, end_extra, startWeight.bio, startWeight.rest_ppp]);

  const saveFailedDrivingRequest = useCallback(
    async (requestBody: IDrivingRequest) => {
      const allFailedDrivingRequests = [];
      const failedDrivingRequests = await getResponse("failedDrivingRequests");

      if (failedDrivingRequests) {
        allFailedDrivingRequests.push(...failedDrivingRequests);
      }
      allFailedDrivingRequests.push({
        requestBody,
        token,
      });
      setResponse("failedDrivingRequests", allFailedDrivingRequests);
    },
    [token]
  );

  const onSave = useCallback(() => {
    async function putWeight() {
      const request = isDriving ? endWeightRequest : startWeightRequest;
      try {
        await superagent
          .put(DEFAULT_API_CONFIG.url + "/diaryorder/job")
          .send(request)
          .set("Accept", "application/json")
          .set("gm_session_id", token ?? "");
      } catch (error) {
        console.error("Error response", error);
        saveFailedDrivingRequest(request);
      }
    }
    if (isDriving) {
      putWeight();
      localStorage.removeItem(LOCAL_STORAGE.JOB_UUID);
      localStorage.removeItem(LOCAL_STORAGE.TOTAL_WEIGHT);
      localStorage.removeItem(LOCAL_STORAGE.STARTWEIGHT);
    } else {
      localStorage.setItem(
        LOCAL_STORAGE.STARTWEIGHT,
        JSON.stringify(selectedKilos)
      );
      localStorage.setItem(LOCAL_STORAGE.TOTAL_WEIGHT, "[]");
      localStorage.setItem(LOCAL_STORAGE.JOB_UUID, generateUuid);
      putWeight();
    }
    onClose();
  }, [
    endWeightRequest,
    generateUuid,
    token,
    isDriving,
    onClose,
    saveFailedDrivingRequest,
    selectedKilos,
    startWeightRequest,
  ]);

  return (
    <div className="driving-modal">
      {!isDriving ? (
        <div className="driving-modal-body">
          <Dropdown
            title="Rest/PPP"
            type="number"
            onSelectChange={(value) =>
              handleKilosOptionChange("rest_ppp", parseInt(value ?? "0"))
            }
            placeholder="0"
            selectedValue={selectedKilos.rest_ppp || 0}
          />
          <Dropdown
            title="Bio"
            type="number"
            onSelectChange={(value) =>
              handleKilosOptionChange("bio", parseInt(value ?? "0"))
            }
            placeholder="0"
            selectedValue={selectedKilos.bio || 0}
          />
        </div>
      ) : (
        <>
          <div className="driving-modal-body">
            <Dropdown
              title={t("pages.orders.totalWeight")}
              type="number"
              onSelectChange={(value) =>
                handleKilosOptionChange(
                  "total_final_weight",
                  parseInt(value ?? "0")
                )
              }
              placeholder="0"
              selectedValue={selectedKilos.total_final_weight || 0}
            />
            <Dropdown
              title={t("pages.orders.afterRestPPP")}
              type="number"
              onSelectChange={(value) =>
                handleKilosOptionChange(
                  "after_rest_ppp",
                  parseInt(value ?? "0")
                )
              }
              disabled={afterRestPPPDisabled}
              placeholder="0"
              selectedValue={selectedKilos.after_rest_ppp || 0}
            />
            <Dropdown
              title={t("pages.orders.afterBio")}
              type="number"
              onSelectChange={(value) =>
                handleKilosOptionChange("after_bio", parseInt(value ?? "0"))
              }
              disabled={afterBioDisabled}
              placeholder="0"
              selectedValue={selectedKilos.after_bio || 0}
            />
          </div>
          {shouldDisableEndDrivingButton && (
            <Text
              className="weight-warning"
              text={t("pages.orderInfo.drivingModal.weightWarning")}
              size="xxs"
            />
          )}

          <div className="driving-modal-sum">
            <div className="driving-modal-sum-title">
              <Text
                text={t("pages.orderInfo.drivingModal.totalWeight")}
                size="xxs"
                fontWeight={600}
              />
            </div>
            <div
              className={`driving-modal-sum-item ${
                isMobileView ? "mobile" : ""
              }`}
            >
              <Text
                className="rest-ppp"
                text={"Rest/PPP:" + " " + sumRestPPP + "kg"}
                size="xxs"
              />
              <Text
                className="bio"
                text={"Bio:" + " " + sumBio + "kg"}
                size="xxs"
              />
              <Text
                className="total"
                text={
                  t("pages.orderInfo.drivingModal.total") +
                  (sumRestPPP + sumBio) +
                  "kg"
                }
                size="xxs"
              />
            </div>
          </div>
          <div className="driving-modal-sum">
            <div className="driving-modal-sum-title">
              <Text
                text={t("pages.orderInfo.drivingModal.calculatedEndWeight")}
                size="xxs"
                fontWeight={600}
              />
            </div>
            <div
              className={`driving-modal-sum-item ${
                isMobileView ? "mobile" : ""
              }`}
            >
              <Text
                className="rest-ppp"
                text={"Rest/PPP:" + " " + end_default + "kg"}
                size="xxs"
              />
              <Text
                className="bio"
                text={"Bio:" + " " + end_extra + "kg"}
                size="xxs"
              />
              <Text
                className="total"
                text={
                  t("pages.orderInfo.drivingModal.total") +
                  selectedKilos.total_final_weight +
                  "kg"
                }
                size="xxs"
              />
            </div>
            <div className="driving-modal-sum-item"></div>
          </div>
        </>
      )}
      <div className="driving-modal-footer">
        <button className="btn-light" onClick={onClose}>
          {t("common.cancel")}
        </button>
        {!isDriving ? (
          <button
            className="btn"
            onClick={onSave}
            disabled={shouldDisableStartDrivingButton}
          >
            {t("pages.orders.startDriving")}
          </button>
        ) : (
          <button
            className="btn"
            onClick={onSave}
            disabled={shouldDisableEndDrivingButton}
          >
            {t("pages.orders.endDriving")}
          </button>
        )}
      </div>
    </div>
  );
};
