import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { getResponse } from "../../utils/indexedDb";
import { Requests } from "../../utils/Requests";
import { t } from "i18next";

interface OfflineContextType {
  isOnline: boolean;
  setIsOnline: (isOnline: boolean) => void;
  regRequestsInQueue: number;
  setRegRequestsInQueue: (regRequestsInQueue: number) => void;
  drivingRequestsInQueue: number;
  setDrivingRequestsInQueue: (drivingRequestsInQueue: number) => void;
  messageRequestsInQueue: number;
  setMessageRequestsInQueue: (messageRequestsInQueue: number) => void;
  createPointRequestsInQueue: number;
  setCreatePointRequestsInQueue: (createPointRequestsInQueue: number) => void;
  createLineRequestsInQueue: number;
  setCreateLineRequestsInQueue: (createLineRequestsInQueue: number) => void;
  showSyncedDataText: boolean;
  setShowSyncedDataText: (showSyncedDataText: boolean) => void;
  offlineString: string;
}

const OfflineContext = createContext<OfflineContextType | undefined>(undefined);

interface OfflineProviderProps {
  children: React.ReactNode;
}

// Create the provider component
export const OfflineProvider = ({ children }: OfflineProviderProps) => {
  const { retryRequests } = Requests();
  const [isOnline, setIsOnline] = useState<boolean>(true);
  const [regRequestsInQueue, setRegRequestsInQueue] = useState<number>(0);
  const [drivingRequestsInQueue, setDrivingRequestsInQueue] =
    useState<number>(0);
  const [messageRequestsInQueue, setMessageRequestsInQueue] =
    useState<number>(0);
  const [createPointRequestsInQueue, setCreatePointRequestsInQueue] =
    useState<number>(0);
  const [createLineRequestsInQueue, setCreateLineRequestsInQueue] =
    useState<number>(0);
  const [showSyncedDataText, setShowSyncedDataText] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener("online", () => setIsOnline(true));
    window.addEventListener("offline", () => setIsOnline(false));

    return () => {
      window.removeEventListener("online", () => setIsOnline(true));
      window.removeEventListener("offline", () => setIsOnline(false));
    };
  }, []);

  // Keeps fetching requests from indexedDB
  useEffect(() => {
    const interval = setInterval(() => {
      async function fetchData() {
        const requestsInQueue = await getResponse("failedRegRequests");
        setRegRequestsInQueue(requestsInQueue?.length);
        const drivingRequestsInQueue = await getResponse(
          "failedDrivingRequests"
        );
        setDrivingRequestsInQueue(drivingRequestsInQueue?.length);
        const messageRequestsInQueue = await getResponse(
          "failedMessageRequests"
        );
        setMessageRequestsInQueue(messageRequestsInQueue?.length);
        const createPointRequestsInQueue = await getResponse(
          "failedCreatePointRequests"
        );
        setCreatePointRequestsInQueue(createPointRequestsInQueue?.length);
        const createLineRequestsInQueue = await getResponse(
          "failedCreateLineRequests"
        );
        setCreateLineRequestsInQueue(createLineRequestsInQueue?.length);
      }
      fetchData();
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  // Deviation messages
  useEffect(() => {
    const interval = setInterval(() => {
      if (isOnline && messageRequestsInQueue > 0) {
        retryRequests(
          "failedMessageRequests",
          "/diaryorder/diaryDetailV2/createMultipleDetailsGeneric",
          "post"
        );
        setShowSyncedDataText(true);
      } else if (isOnline && messageRequestsInQueue === 0) {
        setShowSyncedDataText(false);
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [isOnline, messageRequestsInQueue, retryRequests]);

  // Message object point
  useEffect(() => {
    const interval = setInterval(() => {
      if (isOnline && createPointRequestsInQueue > 0) {
        retryRequests(
          "failedCreatePointRequests",
          "/diaryorder/messageV2/createPoint",
          "post"
        );
        setShowSyncedDataText(true);
      } else if (isOnline && createPointRequestsInQueue === 0) {
        setShowSyncedDataText(false);
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [isOnline, createPointRequestsInQueue, retryRequests]);

  // Message object line
  useEffect(() => {
    const interval = setInterval(() => {
      if (isOnline && createLineRequestsInQueue > 0) {
        retryRequests(
          "failedCreateLineRequests",
          "/diaryorder/messageV2/createLine",
          "post"
        );
        setShowSyncedDataText(true);
      } else if (isOnline && createLineRequestsInQueue === 0) {
        setShowSyncedDataText(false);
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [isOnline, createLineRequestsInQueue, retryRequests]);

  // Registrations
  useEffect(() => {
    const interval = setInterval(() => {
      if (isOnline && regRequestsInQueue > 0) {
        retryRequests(
          "failedRegRequests",
          "/diaryorder/diarydetailV2/updateMultipleDetails",
          "post"
        );
        setShowSyncedDataText(true);
      } else if (isOnline && regRequestsInQueue === 0) {
        setShowSyncedDataText(false);
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [regRequestsInQueue, isOnline, retryRequests]);

  // Driving jobs
  useEffect(() => {
    const interval = setInterval(() => {
      if (isOnline && drivingRequestsInQueue > 0) {
        retryRequests("failedDrivingRequests", "/diaryorder/job", "put");
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [drivingRequestsInQueue, isOnline, retryRequests]);

  const offlineString = useMemo(() => {
    if (!isOnline) {
      return t("pages.orderInfo.offlineTextQueue", {
        amount:
          (regRequestsInQueue ?? 0) +
          (messageRequestsInQueue ?? 0) +
          (createPointRequestsInQueue ?? 0) +
          (createLineRequestsInQueue ?? 0),
      });
    }
    if (
      isOnline &&
      (regRequestsInQueue > 0 ||
        messageRequestsInQueue > 0 ||
        createPointRequestsInQueue > 0 ||
        createLineRequestsInQueue > 0)
    ) {
      return t("pages.orderInfo.onlineText", {
        amount:
          (regRequestsInQueue ?? 0) +
          (messageRequestsInQueue ?? 0) +
          (createPointRequestsInQueue ?? 0) +
          (createLineRequestsInQueue ?? 0),
      });
    }
    if (isOnline && showSyncedDataText) {
      return t("pages.orderInfo.syncedDataText");
    }
    return "";
  }, [
    isOnline,
    regRequestsInQueue,
    messageRequestsInQueue,
    showSyncedDataText,
    createPointRequestsInQueue,
    createLineRequestsInQueue,
  ]);

  return (
    <OfflineContext.Provider
      value={{
        isOnline,
        setIsOnline,
        regRequestsInQueue,
        setRegRequestsInQueue,
        drivingRequestsInQueue,
        setDrivingRequestsInQueue,
        messageRequestsInQueue,
        setMessageRequestsInQueue,
        createPointRequestsInQueue,
        setCreatePointRequestsInQueue,
        createLineRequestsInQueue,
        setCreateLineRequestsInQueue,
        showSyncedDataText,
        setShowSyncedDataText,
        offlineString,
      }}
    >
      {children}
    </OfflineContext.Provider>
  );
};

// Custom hook to use the OfflineContext
export const useOffline = () => {
  const context = useContext(OfflineContext);

  // Ensure the context is not undefined before using it
  if (context === undefined) {
    throw new Error("useOffline must be used within a OfflineProvider");
  }

  return context;
};
