import React, { useCallback, useEffect, useMemo } from "react";
import PageLayout from "../../components/pagelayout/PageLayout";
import { Text } from "../../components/text/Text";
import "./Orders.scss";
import OrderList from "../../components/order-list/OrderList";
import { t } from "i18next";
import { getResponse, setResponse } from "../../utils/indexedDb";
import { DEFAULT_API_CONFIG } from "../../api/api-config";
import useDataFetch from "../../hooks/useDataFetch";
import { ILayer, IMapLayers, InternalMessage } from "../../utils/data-types";
import LOCAL_STORAGE from "../../constants/LocalStorage";
import { useInternalMessages } from "../../context/internal-messages-context/InternalMessagesContext";
import { useVectorLayer } from "../../context/vector-layer-context/VectorLayerContext";
import { useOffline } from "../../context/offline-context/OfflineContext";

export const Orders = () => {
  const { offlineString, isOnline } = useOffline();
  const { saveInternalMessages } = useInternalMessages();
  const { setOtherVectorLayers, setVectorLayerSymbols } = useVectorLayer();

  const getActiveLayersNearbyUrl = useMemo<string>(() => {
    return DEFAULT_API_CONFIG.url + "/diaryorder/feature/getActiveLayersNearby";
  }, []);

  const getActiveMapLayersUrl = useMemo<string>(() => {
    return DEFAULT_API_CONFIG.url + "/diaryorder/feature/getActiveMapLayers";
  }, []);

  const [internalMessages] = useDataFetch<InternalMessage[]>(
    `${DEFAULT_API_CONFIG.url}/diaryorder/tasks/getOther`,
    "POST"
  );

  const [messageCodes] = useDataFetch(
    `${DEFAULT_API_CONFIG.url}/diaryorder/codeV2/getcodes4offline`,
    "GET"
  );

  useEffect(() => {
    if (messageCodes) {
      localStorage.setItem(
        LOCAL_STORAGE.MESSAGE_CODES,
        JSON.stringify(messageCodes)
      );
    }
  }, [messageCodes]);

  useEffect(() => {
    if (internalMessages) {
      saveInternalMessages(internalMessages);
    }
  }, [internalMessages, saveInternalMessages]);

  const [activeLayers] = useDataFetch<ILayer[]>(
    getActiveLayersNearbyUrl,
    "GET"
  );

  const [activeMapLayers] = useDataFetch<IMapLayers>(
    getActiveMapLayersUrl,
    "GET",
    undefined,
    undefined,
    "mapLayers"
  );

  useEffect(() => {
    const layers = [];
    if (!activeMapLayers) return;
    for (const layer of activeMapLayers.maplayer) {
      layers.push(layer);
    }
    setVectorLayerSymbols(activeMapLayers.symbols);
    setOtherVectorLayers(layers);
  }, [activeMapLayers, setOtherVectorLayers, setVectorLayerSymbols]);

  useEffect(() => {
    if (activeLayers) {
      localStorage.setItem(
        LOCAL_STORAGE.MAP_LAYERS_WITH_UUID,
        JSON.stringify(activeLayers)
      );
    }
  }, [activeLayers]);

  const addFailedRequestsDB = useCallback(async () => {
    try {
      const failedRegRequests = await getResponse("failedRegRequests");
      if (failedRegRequests === undefined) {
        await setResponse("failedRegRequests", []);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        await addFailedRequestsDB();
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, [addFailedRequestsDB]);

  return (
    <PageLayout>
      <div className="orders">
        <Text
          className="orders-title"
          text={t("pages.orders.title")}
          size="xxs"
          fontWeight={600}
        />
        <OrderList />
        <Text
          className={`connection-text ${
            isOnline ? "online-text" : "offline-text"
          }`}
          text={offlineString}
          size="xxs"
        />
      </div>
    </PageLayout>
  );
};
