enum LOCAL_STORAGE {
  USER = "user_store",
  RESET_PASSWORD_EMAIL = "reset_password_email",
  RESET_PASSWORD_TOKEN = "reset_password_token",
  CUSTOMER_SEARCH_TERM = "customer_search_term",
  MAP_LAYERS_WITH_UUID = "map_layers_with_uuid",
  MAP_EXTENT = "map_extent",
  ZOOM_LEVEL = "zoom_level",
  SELECTED_FILTER = "selected_filter",
  DROPDOWN_FILTERS = "dropdown_filters",
  JOB_UUID = "job_uuid",
  NEED_STARTWEIGHT = "need_startweight",
  STARTWEIGHT = "startweight",
  TOTAL_WEIGHT = "total_weight",
  SELECTED_MAP_URL = "selected_map_url",
  MESSAGE_CODES = "message_codes",
}

export default LOCAL_STORAGE;
