import React, { useCallback, useState, useEffect, useMemo } from "react";
import { t } from "i18next";
import "./BackgroundMapDownloader.scss";
import { MercatorCoordToTile } from "../../utils/tileMath";
import { Text } from "../text/Text";
import {
  startDownload,
  getFullDownloadState,
  getDownloaderState,
  cancelDownload,
  deleteDownload,
  DownloaderState,
  calculateTotalTilecount,
  getElapsedTime,
} from "../../services/OfflineMapService";
import { Icon } from "../icon/Icon";

//TODO: get config from server
const offlineConfig = {
  url: "https://mapproxy.avinet.no/service?",
  layer: "osm_webmercator",
  tilematrixset: "osm_grid",
  levels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
  bbox: [569685, 8500000, 665995, 8621103],
};

type Corner = {
  x: number;
  y: number;
};

type Corners = {
  nwCorner: Corner;
  neCorner: Corner;
  swCorner: Corner;
  seCorner: Corner;
};

type TileBounds = {
  startX: number;
  stopX: number;
  startY: number;
  stopY: number;
};

const getCornersFromBbox = (bbox: number[]): Corners => {
  const [minX, minY, maxX, maxY] = bbox;
  return {
    nwCorner: { x: minX, y: maxY },
    neCorner: { x: maxX, y: maxY },
    swCorner: { x: minX, y: minY },
    seCorner: { x: maxX, y: minY },
  };
};

function calculateTileBoundsForZoomLevel(
  corners: Corners,
  zoom: number
): TileBounds {
  const nwTile = MercatorCoordToTile(
    corners.nwCorner.x,
    corners.nwCorner.y,
    zoom
  );
  const seTile = MercatorCoordToTile(
    corners.seCorner.x,
    corners.seCorner.y,
    zoom
  );
  // console.log("zoom: ", zoom);
  // console.log("NW tile: ", nwTile);
  // console.log("SE tile: ", seTile);

  const startX = nwTile.tx;
  const stopX = seTile.tx;
  const startY = seTile.ty;
  const stopY = nwTile.ty;

  return { startX, stopX, startY, stopY };
}

const BackgroundMapDownloader: React.FC = () => {
  const tileBoundLevels = useMemo(() => {
    const corners = getCornersFromBbox(offlineConfig.bbox);
    const tileBoundLevels: { [key: number]: TileBounds } = {};

    for (let i = 0; i < offlineConfig.levels.length; i++) {
      const zoom = offlineConfig.levels[i];
      const tileBoundsOnZoomLevel = calculateTileBoundsForZoomLevel(
        corners,
        zoom
      );
      tileBoundLevels[zoom] = tileBoundsOnZoomLevel;
    }
    return tileBoundLevels;
  }, []);
  const [downloadedTiles, setDownloadedTiles] = useState(0);
  const [elapsedTime, setElapsedTime] = useState("00:00:00");
  const [totalTiles, setTotalTiles] = useState(
    calculateTotalTilecount(tileBoundLevels)
  );
  const [alreadyCachedTiles, setAlreadyCachedTiles] = useState(-1);
  const [downloaderState, setDownloaderState] =
    useState<DownloaderState>(getDownloaderState());

  useEffect(() => {
    async function checkCache() {
      if (
        downloaderState === DownloaderState.NOT_STARTED ||
        downloaderState === DownloaderState.COMPLETED ||
        downloaderState === DownloaderState.ERROR
      ) {
        let totalCachedTiles = 0;
        const cacheNames = await caches.keys();
        const cachePromises = cacheNames.map(async (cacheName) => {
          return new Promise<number>((resolve, reject) => {
            caches
              .open(cacheName)
              .then((cache) => {
                cache.keys().then((keys) => {
                  resolve(keys.length);
                });
              })
              .catch((error) => {
                console.error(error);
                reject(error);
              });
          });
        });

        const cacheResults = await Promise.all(cachePromises);
        totalCachedTiles = cacheResults.reduce((acc, curr) => acc + curr, 0);

        setAlreadyCachedTiles(totalCachedTiles);
      }
    }
    checkCache();
  }, [downloaderState]);

  useEffect(() => {
    const interval = setInterval(async () => {
      if (
        downloaderState === DownloaderState.DOWNLOADING ||
        downloaderState === DownloaderState.DELETING ||
        downloaderState === DownloaderState.COMPLETED
      ) {
        const state = await getFullDownloadState();
        setDownloadedTiles(state.downloadedTiles);
        setTotalTiles(state.totalTiles);
        setDownloaderState(state.downloaderState);
        setElapsedTime(getElapsedTime());
      }
    }, 25);

    return () => clearInterval(interval);
  }, [downloaderState]);

  const downloadTiles = useCallback(async () => {
    setDownloaderState(DownloaderState.DOWNLOADING);
    console.log("Downloading tiles");
    setDownloadedTiles(0);

    try {
      await startDownload(tileBoundLevels, offlineConfig);
      console.log("Tile download completed");
      setDownloaderState(DownloaderState.COMPLETED);
    } catch (error) {
      console.error(error);
      setDownloaderState(DownloaderState.ERROR);
    }
  }, [tileBoundLevels]);

  const cancelDownloadHandler = () => {
    cancelDownload();
  };

  const deleteDownloadHandler = async () => {
    setDownloaderState(DownloaderState.DELETING);
    await deleteDownload();
    setDownloaderState(DownloaderState.NOT_STARTED);
  };

  const currentlyDownloadedPercentage = useMemo(() => {
    if (downloaderState === DownloaderState.ERROR) {
      return 0;
    }
    if (downloaderState === DownloaderState.NOT_STARTED) {
      return totalTiles > 0
        ? parseFloat(((alreadyCachedTiles / totalTiles) * 100).toFixed(2))
        : 0;
    }
    return totalTiles > 0
      ? parseFloat(((downloadedTiles / totalTiles) * 100).toFixed(2))
      : 0;
  }, [downloaderState, totalTiles, downloadedTiles, alreadyCachedTiles]);

  const isReadingCacheStatus = useMemo(() => {
    return alreadyCachedTiles === -1;
  }, [alreadyCachedTiles]);

  const lastOfflineSyncTime = useMemo(() => {
    if (
      downloaderState === DownloaderState.COMPLETED ||
      downloaderState === DownloaderState.NOT_STARTED
    ) {
      const dateString = localStorage.getItem("lastOfflineSyncTime");
      if (dateString && dateString !== "00:00:00") {
        return new Date(dateString).toLocaleString();
      }
      return "";
    }
    return "";
  }, [downloaderState]);

  return (
    <div className="background-map-downloader--container">
      <Text
        className="background-map-downloader--header"
        text={t("pages.settings.offline_background_map")}
        size="xxxs"
        fontWeight={600}
      />
      <p className="background-map-downloader--info">
        {t("pages.settings.download_offline_map_info")}
      </p>
      {isReadingCacheStatus && (
        <p>{t("pages.settings.reading_cache_status")}</p>
      )}
      {downloaderState !== DownloaderState.DOWNLOADING &&
        downloaderState !== DownloaderState.DELETING &&
        !isReadingCacheStatus &&
        currentlyDownloadedPercentage < 99.9 && (
          <button className="btn" onClick={downloadTiles}>
            {t("pages.settings.download_offline_map")}
          </button>
        )}
      {downloaderState === DownloaderState.DOWNLOADING && (
        <button className="btn-light" onClick={cancelDownloadHandler}>
          {t("pages.settings.cancel")}
        </button>
      )}
      {downloaderState === DownloaderState.DOWNLOADING && (
        <div>
          <p>
            {t("pages.settings.downloading")}{" "}
            {totalTiles > 0
              ? ((downloadedTiles / totalTiles) * 100).toFixed(2)
              : 0}{" "}
            % {t("pages.settings.of_total_tiles_downloaded")}
          </p>
          <p>
            {t("pages.settings.elapsed_time")}: {elapsedTime}
          </p>
        </div>
      )}
      {downloaderState === DownloaderState.ERROR && (
        <p>{t("pages.settings.download_error")}</p>
      )}

      {downloaderState === DownloaderState.DELETING && (
        <p>{t("pages.settings.deleting")}</p>
      )}

      {alreadyCachedTiles > 0 &&
        downloaderState !== DownloaderState.DOWNLOADING &&
        downloaderState !== DownloaderState.DELETING &&
        !isReadingCacheStatus && (
          <div>
            <p
              className={currentlyDownloadedPercentage > 99.99 ? "success" : ""}
            >
              {currentlyDownloadedPercentage > 99.99 ? (
                <Icon name="checkmarkFluent" />
              ) : (
                ""
              )}{" "}
              {currentlyDownloadedPercentage}%{" "}
              {t("pages.settings.of_tiles_previously_downloaded")}
            </p>
            {lastOfflineSyncTime &&
              (downloaderState === DownloaderState.COMPLETED ||
                downloaderState === DownloaderState.NOT_STARTED) && (
                <p>
                  {t("pages.settings.last_sync")}: {lastOfflineSyncTime}
                </p>
              )}
            <p>
              {t("pages.settings.elapsed_time")}: {elapsedTime}
            </p>
            <button className="btn-light" onClick={deleteDownloadHandler}>
              {t("pages.settings.delete_previously_downloaded_tiles")}
            </button>
          </div>
        )}
    </div>
  );
};

export default BackgroundMapDownloader;
