import React, { useCallback, useContext, useState } from "react";
import { Text } from "../text/Text";

import "./ConfirmationModalContent.scss";
import { t } from "i18next";
import { IOrder, OrderObject } from "../../utils/data-types";
import { Icon } from "../icon/Icon";
import superagent from "superagent";
import { DEFAULT_API_CONFIG } from "../../api/api-config";
import { getResponse, setResponse } from "../../utils/indexedDb";
import { UserContext } from "../../context/user-context/UserContext";
import { useParams } from "react-router-dom";

export const ConfirmationModalContent = ({
  onConfirm,
  onSyncOrder,
  onclose,
  orderObject,
}: {
  onConfirm: () => void;
  onSyncOrder: () => void;
  onclose: () => void;
  orderObject: OrderObject | undefined;
}) => {
  const { token } = useContext(UserContext);
  const { orderId: orderId, selectedFilter: selectedFilter } = useParams();

  const [hasSynced, setHasSynced] = useState(true);

  const orderTitle = useCallback((text: OrderObject | undefined) => {
    if (!text) {
      return "";
    }
    const splitBySpace = text.info1.split(" ");
    return splitBySpace.slice(1).join(" ");
  }, []);

  const confirmText = t("pages.orderInfo.confirmationModal.text", {
    orderObject: orderTitle(orderObject),
  });

  const syncText = t("pages.orderInfo.confirmationModal.syncText");

  const syncOrder = useCallback(async () => {
    try {
      const response = await superagent
        .get(
          DEFAULT_API_CONFIG.url +
            `/diaryorder/order/getOrderWithOrderObjects?order_id=${orderId}`
        )
        .set("Accept", "application/json")
        .set("gm_session_id", token ?? "");

      if (selectedFilter) {
        const indexedDBOrders = await getResponse(selectedFilter);
        if (indexedDBOrders) {
          const updatedOrders = indexedDBOrders.map(
            (indexedDBOrder: IOrder) => {
              if (indexedDBOrder.id === Number(orderId)) {
                return response.body[0];
              }
              return indexedDBOrder;
            }
          );
          await setResponse(selectedFilter, updatedOrders);
        }
      }
      setHasSynced(true);
    } catch (error) {
      console.error("Error syncing order:", error);
    }
  }, [orderId, selectedFilter, token]);

  const handleDesyncedOrder = useCallback(async () => {
    onclose();
    await syncOrder();
    onSyncOrder();
  }, [onclose, syncOrder, onSyncOrder]);

  const handleOnConfirm = useCallback(async () => {
    onConfirm();
    setHasSynced(false);
  }, [onConfirm]);

  return (
    <div className="confirmation-modal-content">
      <div className="confirmation-modal-content__text">
        <Text text={hasSynced ? confirmText : syncText} size="xs" />
      </div>
      <div className="confirmation-modal-content__buttons">
        {hasSynced ? (
          <button className="btn" onClick={handleOnConfirm}>
            <Icon name="checkmarkFluent" />
            {t("common.yes")}
          </button>
        ) : (
          <button className="btn" onClick={handleDesyncedOrder}>
            <Icon name="arrowSync" />
            {t("pages.orderInfo.desyncedOrderModal.sync")}
          </button>
        )}
        {hasSynced && (
          <button className="btn-light" onClick={onclose}>
            <Icon name="cross" />
            {t("common.no")}
          </button>
        )}
      </div>
    </div>
  );
};
