import { format, isToday, differenceInDays, parseISO } from "date-fns";
import { enUS, nb } from "date-fns/locale";

export function formatDate(
  dateString: string,
  selectedLanguage: string,
  t: (key: string) => string
): string {
  const date = parseISO(dateString);

  if (isToday(date)) {
    return format(date, selectedLanguage === "en" ? "hh:mm a" : "HH:mm", {
      locale: selectedLanguage === "en" ? enUS : nb,
    });
  }

  const daysAgo = differenceInDays(new Date(), date);

  if (daysAgo >= 1 && daysAgo <= 8) {
    const dayKey = format(date, "eee", { locale: enUS })
      .toLowerCase()
      .slice(0, 3);
    return `${t(`days.${dayKey}`)}. ${format(date, "dd.MM")}`;
  }

  return format(date, "dd.MM.yyyy");
}
