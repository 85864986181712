import { createContext, ReactNode, useContext, useState } from "react";

/* eslint-disable @typescript-eslint/no-explicit-any */

interface MapBoxDataContextType {
  mapBoxData: any;
  setMapBoxData: (mapBoxData: any) => void;
}

const MapBoxDataContext = createContext<MapBoxDataContextType | undefined>(
  undefined
);

interface MapBoxDataProviderProps {
  children: ReactNode;
}

// Create the provider component
export const MapBoxDataProvider = ({ children }: MapBoxDataProviderProps) => {
  const [mapBoxData, setMapBoxData] = useState<any>(false);

  return (
    <MapBoxDataContext.Provider value={{ mapBoxData, setMapBoxData }}>
      {children}
    </MapBoxDataContext.Provider>
  );
};

// Custom hook to use the MapBoxDataContext
export const useMapBoxData = () => {
  const context = useContext(MapBoxDataContext);

  // Ensure the context is not undefined before using it
  if (context === undefined) {
    throw new Error("useMapBoxData must be used within a MapBoxDataProvider");
  }

  return context;
};
