import React from "react";
import { Text } from "../text/Text";
import { t } from "i18next";
import { IOrder } from "../../utils/data-types";
import "./OrderInfoModalContent.scss";

interface OrderInfoModalContentProps {
  order: IOrder | undefined;
  onClose: () => void;
}

export const OrderInfoModalContent = ({
  order,
  onClose,
}: OrderInfoModalContentProps) => {
  return (
    <div className="order-info-modal">
      <div className="order-info-modal-body">
        <div className="order-info-modal-body-pair">
          <Text text="ID" size="xxs" fontWeight={600} />
          <Text
            text={order?.id.toString() ?? t("pages.message.notSpecified")}
            size="xxs"
          />
        </div>
        <div className="order-info-modal-body-pair">
          <Text
            text={t("pages.orderInfo.status")}
            size="xxs"
            fontWeight={600}
          />
          <Text
            text={order?.status ?? t("pages.message.notSpecified")}
            size="xxs"
          />
        </div>
        <div className="order-info-modal-body-pair">
          <Text text={t("pages.orderInfo.type")} size="xxs" fontWeight={600} />
          <Text
            text={order?.typedesc.toString() ?? t("pages.message.notSpecified")}
            size="xxs"
          />
        </div>

        <div className="order-info-modal-body-pair">
          <Text text={t("pages.orderInfo.week")} size="xxs" fontWeight={600} />
          <Text
            text={order?.week.toString() ?? t("pages.message.notSpecified")}
            size="xxs"
          />
        </div>
        <div className="order-info-modal-body-pair">
          <Text
            text={t("pages.orderInfo.priority")}
            size="xxs"
            fontWeight={600}
          />
          <Text
            text={order?.priority ?? t("pages.message.notSpecified")}
            size="xxs"
          />
        </div>
        <div className="order-info-modal-body-pair">
          <Text
            text={t("pages.orderInfo.responsible")}
            size="xxs"
            fontWeight={600}
          />
          <Text
            text={order?.responsible ?? t("pages.message.notSpecified")}
            size="xxs"
          />
        </div>
        <div className="order-info-modal-body-pair">
          <Text
            text={t("pages.orderInfo.description")}
            size="xxs"
            fontWeight={600}
          />
          <Text
            text={
              order?.description === ""
                ? t("pages.message.notSpecified")
                : (order?.description ?? t("pages.message.notSpecified"))
            }
            size="xxs"
          />
        </div>
      </div>

      <div className="order-info-modal-footer">
        <button className="btn" onClick={onClose}>
          {t("common.close")}
        </button>
      </div>
    </div>
  );
};
