import { useContext, useEffect, useState } from "react";
import superagent from "superagent";

import { UserContext } from "../context/user-context/UserContext";
import { setResponse, getResponse } from "../utils/indexedDb";

function useDataFetch<T>(
  url: string,
  method: "GET" | "POST" | "PUT" | "DELETE",
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  requestBody?: Record<string, any>,
  filterCode?: string | undefined,
  indexedDBKey?: string
): [T | undefined, boolean, string?] {
  const user = useContext(UserContext);
  const [gmSessionId] = useState<string>(user.token ?? "");
  const [data, setData] = useState<T | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    let isMounted = true;

    if (!url || url.includes("undefined") || !gmSessionId) {
      return;
    }
    if (requestBody?.mainfilter === "" && url.includes("tasks/getMine")) {
      return;
    }

    const fetchData = async () => {
      setLoading(true);
      try {
        let req: superagent.Request;
        switch (method) {
          case "GET":
            req = superagent.get(url);
            break;
          case "POST":
            req = superagent.post(url);
            break;
          case "PUT":
            req = superagent.put(url);
            break;
          case "DELETE":
            req = superagent.delete(url);
            break;
          default:
            throw new Error("Unsupported HTTP method");
        }

        req.set("gm_session_id", gmSessionId);

        if (requestBody) {
          req.send(requestBody);
        }

        const res = await req;
        if (isMounted) {
          setData(res.body);
          setLoading(false);
          if (indexedDBKey === "getMine") {
            await setResponse(filterCode, res.body); // Store orders in IndexedDB
          } else if (indexedDBKey === "mapLayers") {
            await setResponse("mapLayers", res.body); // Store mapLayers in IndexedDB
          }
        }
      } catch (err) {
        setError((err as Error).message);
        console.log(error);
        if (isMounted) {
          setLoading(false);

          const cachedResponse = await getResponse(
            (indexedDBKey === "getMine" ? filterCode : indexedDBKey) ?? ""
          ); // Retrieve response from IndexedDB
          if (cachedResponse) {
            setData(cachedResponse);
          }
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [url, gmSessionId, method, requestBody, error, filterCode, indexedDBKey]);

  return [data, loading, error];
}

export default useDataFetch;
