import { SVGProps } from "react";

export const AVLogoIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 641.3 455" {...props}>
      <g>
        <path
          d="M238.7,151.9c6-14.4-0.9-30.9-15.3-36.9c-14.4-6-30.9,0.9-36.9,15.3L115,303.1c-6,14.4,0.9,30.9,15.3,36.9
          s30.9-0.9,36.9-15.3L238.7,151.9z"
          fill="#FFFFFF"
        />
        <path
          d="M331,151.9c-6-14.4,0.9-30.9,15.3-36.9c14.4-6,30.9,0.9,36.9,15.3l71.6,172.8c6,14.4-0.9,30.9-15.3,36.9
          c-14.4,6-30.9-0.9-36.9-15.3L331,151.9z"
          fill="#FFFFFF"
        />
        <path
          d="M474.1,130.3c6-14.4,22.5-21.2,36.9-15.3s21.2,22.5,15.3,36.9l-24,57.9c-6,14.4-22.5,21.2-36.9,15.3
          c-14.4-6-21.2-22.5-15.3-36.9L474.1,130.3z"
          fill="#FFFFFF"
        />
        <path
          d="M286.3,245.2c-6-14.4-22.5-21.2-36.9-15.3c-14.4,6-21.2,22.5-15.3,36.9l24,57.9c6,14.4,22.5,21.2,36.9,15.3
          c14.4-6,21.2-22.5,15.3-36.9L286.3,245.2z"
          fill="#FFFFFF"
        />
      </g>
    </svg>
  );
};
