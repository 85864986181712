import { useContext } from "react";
import { UserContext } from "../context/user-context/UserContext";
import { getResponse, setResponse } from "./indexedDb";
import superagent from "superagent";
import { DEFAULT_API_CONFIG } from "../api/api-config";
import { IMessage } from "./data-types";

export const Requests = () => {
  const { token, user } = useContext(UserContext);

  async function retryRequests(
    response: string,
    url: string,
    method: "get" | "post" | "put" | "delete" | "patch"
  ) {
    if (!token || !user.value?.expiration_time) return;

    try {
      let failedRequests = await getResponse(response);

      for (const request of failedRequests) {
        try {
          await superagent[method](DEFAULT_API_CONFIG.url + url)
            .send(request.requestBody)
            .set("Accept", "application/json")
            .set("gm_session_id", token);

          const updatedFailedRequests = failedRequests.filter(
            (failedRequest: IMessage) => failedRequest !== request
          );

          await setResponse(response, updatedFailedRequests);

          failedRequests = updatedFailedRequests;
        } catch (error) {
          console.error("Retry failed for request:", request, "Error:", error);
        }
      }
    } catch (error) {
      console.error(
        "An error occurred during retrying failed requests:",
        error
      );
    }
  }
  return { retryRequests };
};
