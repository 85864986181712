import { MercatorCoordToTile, TileToMercatorCoord } from "../utils/tileMath";

const OSM_CACHE_NAME = "osm-cache";
const OSM_CACHE_ZOOM_PARTITION_LEVEL = 12;

export enum DownloaderState {
  NOT_STARTED = "NOT_STARTED",
  DOWNLOADING = "DOWNLOADING",
  ERROR = "ERROR",
  DELETING = "DELETING",
  COMPLETED = "COMPLETED",
}

type TileBounds = {
  startX: number;
  stopX: number;
  startY: number;
  stopY: number;
};

let downloadedTiles = 0;
let totalTiles = 0;
let downloaderState = DownloaderState.NOT_STARTED;
let abortController: AbortController | null = null;
let startTime: number | null = null;
let endTime: number | null = null;

function formatTime(ms: number) {
  const totalSeconds = Math.floor(ms / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
}

export function getElapsedTime() {
  if (startTime === null) return "00:00:00";
  const endTimeToUse = endTime !== null ? endTime : Date.now();
  return formatTime(endTimeToUse - startTime);
}

export async function startDownload(
  tileBoundLevels: { [key: number]: TileBounds },
  offlineConfig: any
) {
  abortController = new AbortController();
  const { signal } = abortController;
  setSyncStatus(true);
  downloaderState = DownloaderState.DOWNLOADING;
  downloadedTiles = 0;

  //initiate timer
  startTime = Date.now();
  endTime = null;

  const tileCount = calculateTotalTilecount(tileBoundLevels);
  totalTiles = tileCount;

  for (const key in Object.keys(tileBoundLevels)) {
    const zoom = parseInt(key);
    const tileBounds = tileBoundLevels[zoom];
    await downloadTilesOnZoomLevel(tileBounds, zoom, offlineConfig, signal);
  }

  downloaderState = DownloaderState.COMPLETED;
  setSyncStatus(false);
  endTime = Date.now();
  localStorage.setItem("lastOfflineSyncTime", new Date().toISOString());
}

export function calculateTotalTilecount(tileBoundLevels: {
  [key: number]: TileBounds;
}) {
  let tileCount = 0;
  for (const key in Object.keys(tileBoundLevels)) {
    const zoom = parseInt(key);
    const { startX, stopX, startY, stopY } = tileBoundLevels[zoom];
    const tilesOnLevel = (stopX + 1 - startX) * (startY + 1 - stopY);
    tileCount += tilesOnLevel;
    // console.log(`tiles on level ${zoom}: ${tilesOnLevel}`);
  }
  // console.log("Total tiles: ", tileCount);
  return tileCount;
}

async function downloadTilesOnZoomLevel(
  tileBounds: TileBounds,
  zoom: number,
  offlineConfig: any,
  signal: AbortSignal
) {
  const { startX, stopX, startY, stopY } = tileBounds;
  // const queue: Promise<void>[] = [];

  // Limit the number of concurrent downloads to avoid overloading the browser/server
  // 1 uses ~6 minutes to download zoom 16
  // 20, uses ~ 1.5 minutes
  // 8 seems to be a good balance, at 2 minutes, and avoids hammering the server
  // const concurrencyLimit = 8;

  for (let x = startX; x <= stopX; x++) {
    for (let y = startY; y >= stopY; y--) {
      if (signal.aborted) {
        console.log("Download aborted");
        downloaderState = DownloaderState.NOT_STARTED;
        return;
      }
      const url =
        `${offlineConfig.url}LAYER=${offlineConfig.layer}&style=&tilematrixset=${offlineConfig.tilematrixset}&Service=WMTS&Request=GetTile&Version=1.0.0&Format=png&TileMatrix=${zoom}&TileCol=${x}&TileRow=${y}`.toLowerCase();
      try {
        const response = await fetch(url, { signal });

        if (!response.ok) {
          console.error(
            `Failed to fetch tile at ${zoom}/${x}/${y}. Url: ${url}, response: ${response}`
          );
        }
        downloadedTiles++;
        const tileCacheName = resolveCacheName(url);
        const cache = await caches.open(tileCacheName);
        await cache.put(url, response);

        // const downloadPromise = fetch(url, { signal }).then(
        //   async (response) => {
        //     if (!response.ok) {
        //       console.error(
        //         `Failed to fetch tile at ${zoom}/${x}/${y}. Url: ${url}, response: ${response}`
        //       );
        //     }
        //     downloadedTiles++;
        //     const tileCacheName = resolveCacheName(url);
        //     const cache = await caches.open(tileCacheName);
        //     await cache.put(url, response);
        //   }
        // );
        // queue.push(downloadPromise);
        // if (queue.length >= concurrencyLimit) {
        //   await Promise.allSettled(queue);
        //   queue.length = 0; // Clear the queue
        // }
      } catch (error) {
        console.error(error);
      }
    }
  }

  // await Promise.allSettled(queue);
  console.log(
    `Finished downloading zoom level ${zoom} - ${downloadedTiles} tiles`
  );
}

export function resolveCacheName(tileUrl: string) {
  const url = new URL(tileUrl);
  const zoom = parseInt(url.searchParams.get("tilematrix") || "0");
  const tx = parseInt(url.searchParams.get("tilecol") || "0");
  const ty = parseInt(url.searchParams.get("tilerow") || "0");

  const centerCoords = TileToMercatorCoord(tx, ty, zoom);
  const cacheTileNr = MercatorCoordToTile(
    centerCoords.mx,
    centerCoords.my,
    OSM_CACHE_ZOOM_PARTITION_LEVEL
  );
  const cacheName = `${OSM_CACHE_NAME}-${OSM_CACHE_ZOOM_PARTITION_LEVEL}-${cacheTileNr.tx}-${cacheTileNr.ty}`;
  return cacheName;
}

export function cancelDownload() {
  if (abortController) {
    abortController.abort();
  }
}

export async function deleteDownload() {
  downloadedTiles = 0;
  downloaderState = DownloaderState.DELETING;
  const cacheNames = await caches.keys();
  for (const cacheName of cacheNames) {
    if (cacheName.startsWith(OSM_CACHE_NAME)) {
      await caches.delete(cacheName);
    }
  }
  downloaderState = DownloaderState.NOT_STARTED;
  localStorage.removeItem("lastOfflineSyncTime");
}

export async function getFullDownloadState() {
  return {
    downloadedTiles,
    totalTiles,
    downloaderState,
  };
}

export function getDownloaderState() {
  return downloaderState;
}

function setSyncStatus(isSyncing: boolean) {
  if (navigator.serviceWorker.controller) {
    navigator.serviceWorker.controller.postMessage({
      type: "SET_SYNC_STATUS",
      isCurrentlySyncing: isSyncing,
    });
  }
}
