import "./LoadingMask.scss";

export function LoadingMask({
  label,
  small,
  smallest,
}: {
  label?: string;
  small?: boolean;
  smallest?: boolean;
}) {
  return (
    <div className={`loading-mask ${smallest ? "smallest" : ""}`}>
      <h3>
        <span className="label">{label}</span>{" "}
        <span
          className={`loading-spinner ${small ? "small" : ""} ${
            smallest ? "smallest" : ""
          }`}
        >
          <span />
          <span />
          <span />
        </span>
      </h3>
    </div>
  );
}
