import React, {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Feature from "ol/Feature";
import WKT from "ol/format/WKT";
import { Text } from "../../components/text/Text";
import Icon from "@avinet/adaptive-ui-core/ui/Icon";
import { Outlet } from "react-router-dom";
import PageLayout from "../../components/pagelayout/PageLayout";
import { MapWrapper } from "../../components/map/MapWrapper";
import {
  IRegisterData,
  IDrivingRequest,
  InternalMessage,
} from "../../utils/data-types";
import { t } from "i18next";
import { MapProvider } from "@avinet/adaptive-ui-maps";
import { projection } from "../../constants/Projection";
import { getResponse, setResponse } from "../../utils/indexedDb";
import superagent from "superagent";
import { DEFAULT_API_CONFIG } from "../../api/api-config";
import { MapOptions } from "../../components/map-options/MapOptions";
import { MAP_OPTIONS, STATUSES } from "../../constants/Constants";
import LOCAL_STORAGE from "../../constants/LocalStorage";
import TaskFilterButton from "../../components/buttons/TaskFilterButton";
import Modal from "../../components/modal/Modal";
import { useDrawing } from "../../context/drawing-context/DrawingContext";
import FlipButtons from "../../components/buttons/FlipButtons";
import { useMobileView } from "../../context/mobile-view-context/MobileViewContext";
import { OrderObjectList } from "../../components/order-object-list/OrderObjectList";
import { useOrder } from "../../context/order-context/OrderContext";
import { useInternalMessages } from "../../context/internal-messages-context/InternalMessagesContext";
import { useOffline } from "../../context/offline-context/OfflineContext";
import { useVectorLayer } from "../../context/vector-layer-context/VectorLayerContext";
import { useMapBoxData } from "../../context/map-box-data-context/MapBoxDataContext";

const InternalMessages = () => {
  const {
    isDrawMode,
    setIsDrawMode,
    hasDrawn,
    setHasDrawn,
    setDrawnObject,
    setIsSendingWKT,
  } = useDrawing();
  const { currentOrder } = useOrder();
  const { isMobileView } = useMobileView();
  const { isOnline, offlineString } = useOffline();
  const { isFullscreenMap, onToggleFullscreenMap } = useMobileView();
  const { internalMessages } = useInternalMessages();
  const { selectedTblId } = useVectorLayer();
  const { setMapBoxData } = useMapBoxData();

  const [regRequestsInQueue, setRegRequestsInQueue] = useState<number>(0);
  const [drivingRequestsInQueue, setDrivingRequestsInQueue] =
    useState<number>(0);
  const [showSyncedDataText, setShowSyncedDataText] = useState<boolean>(false);
  const mapContentRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const [selectedBoxId, setSelectedBoxId] = useState<number | undefined>(
    undefined
  );
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [selectedTaskFilter, setSelectedTaskFilter] = useState<string>(
    STATUSES.ALL
  );

  const needStartweight =
    localStorage.getItem(LOCAL_STORAGE.NEED_STARTWEIGHT) === "true";

  const availableFilters = useMemo<string[]>(
    () => [STATUSES.REPORTED, STATUSES.COMPLETED],
    []
  );

  const isRouteSelected = currentOrder !== null;

  const filteredOrderObjects = internalMessages?.filter((task) =>
    selectedTaskFilter === STATUSES.ALL
      ? true
      : selectedTaskFilter === STATUSES.ROUTE
        ? task.route === parseInt(currentOrder?.route ?? "")
        : task.status === selectedTaskFilter
  );

  const features = useMemo<Feature[]>(() => {
    if (!filteredOrderObjects || filteredOrderObjects.length === 0) return [];
    return filteredOrderObjects.map((task) => {
      if (task.wkt === "" || task.wkt === null) {
        return new Feature();
      }
      const feature = new WKT().readFeature(task.wkt);
      feature.setId(task.id);
      feature.setProperties({ status: task.status, name: task.codedesc });
      const geometry = feature.getGeometry();
      if (geometry) {
        geometry.transform(projection, "EPSG:3857");
      }
      return feature;
    });
  }, [filteredOrderObjects]);

  const dummyExtent = useMemo(() => [1000000, 9000000, 400000, 8300000], []);

  const defaultExtent = useMemo(() => [461314, 8462004, 707752, 8715243], []);

  const extent = useMemo(() => {
    const extentFromStore = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE.MAP_EXTENT) ?? ""
    );
    if (
      extentFromStore !== defaultExtent &&
      extentFromStore.every((value: number | null) => value !== null)
    ) {
      return extentFromStore;
    }
    if (features.length === 0) return defaultExtent;
    const extent: number[] = [...dummyExtent];
    for (let i = 0; i < features.length; i++) {
      const geometry = features[i].getGeometry();
      if (geometry) {
        const featureExtent = geometry.getExtent();
        extent[0] = Math.min(extent[0], featureExtent[0]);
        extent[1] = Math.min(extent[1], featureExtent[1]);
        extent[2] = Math.max(extent[2], featureExtent[2]);
        extent[3] = Math.max(extent[3], featureExtent[3]);
      }
    }

    const buffer = Math.max(
      (extent[2] - extent[0]) / 5,
      (extent[3] - extent[1]) / 5
    );
    extent[0] -= buffer;
    extent[1] -= buffer;
    extent[2] += buffer;
    extent[3] += buffer;
    if (extent === dummyExtent) {
      return defaultExtent;
    }
    return extent;
  }, [defaultExtent, features, dummyExtent]);

  const handleSetSelectedBoxId = useCallback(
    (id: number | undefined) => setSelectedBoxId(id),
    []
  );

  const handleBoxClick = useCallback(
    (index: number) => {
      setMapBoxData(undefined);
      handleSetSelectedBoxId(index === selectedBoxId ? undefined : index);
      if (
        index === selectedBoxId ||
        (!isOnline && selectedOption === MAP_OPTIONS.PROPERTIES)
      ) {
        setSelectedOption("");
      } else if (selectedBoxId === null || selectedOption === "") {
        setSelectedOption(MAP_OPTIONS.REGISTER);
      }
    },
    [
      handleSetSelectedBoxId,
      isOnline,
      selectedBoxId,
      selectedOption,
      setMapBoxData,
    ]
  );

  useEffect(() => {
    if (selectedBoxId === null) {
      setIsSendingWKT(false);
    }
  }, [selectedBoxId, setIsSendingWKT]);

  const handleSetSelectedTaskFilter = useCallback((filter: string) => {
    if (filter === STATUSES.ALL) {
      setSelectedTaskFilter(STATUSES.ALL);
    } else {
      setSelectedTaskFilter(filter);
    }
  }, []);

  /**
   * if the selected box is filtered away,
   * deselect the box
   */
  useEffect(() => {
    if (
      selectedBoxId !== null &&
      !internalMessages?.some((obj) => obj.id === selectedBoxId)
    ) {
      setSelectedBoxId(undefined);
    }
  }, [internalMessages, selectedBoxId]);

  const registerUrl = useMemo(
    () =>
      DEFAULT_API_CONFIG.url +
      "/diaryorder/diarydetailV2/updateMultipleDetails",
    []
  );

  const retryFailedRegRequests = useCallback(async () => {
    try {
      let failedRegRequests = await getResponse("failedRegRequests");

      for (const request of failedRegRequests) {
        try {
          await superagent
            .post(registerUrl)
            .send(request.requestBody)
            .set("Accept", "application/json")
            .set("gm_session_id", request.gm_session_id);

          const updatedFailedRegRequests = failedRegRequests.filter(
            (failedRegRequest: IRegisterData) => failedRegRequest !== request
          );

          await setResponse("failedRegRequests", updatedFailedRegRequests);

          failedRegRequests = updatedFailedRegRequests;
        } catch (error) {
          console.error("Retry failed for request:", request, "Error:", error);
        }
      }
    } catch (error) {
      console.error(
        "An error occurred during retrying failed requests:",
        error
      );
    }
  }, [registerUrl]);

  const retryFailedDrivingRequests = useCallback(async () => {
    try {
      let failedDrivingRequests = await getResponse("failedDrivingRequests");

      for (const request of failedDrivingRequests) {
        try {
          await superagent
            .put(DEFAULT_API_CONFIG.url + "/diaryorder/job")
            .send(request.requestBody)
            .set("Accept", "application/json")
            .set("gm_session_id", request.gm_session_id);

          const updatedFailedDrivingRequests = failedDrivingRequests.filter(
            (failedDrivingRequest: IDrivingRequest) =>
              failedDrivingRequest !== request
          );

          await setResponse(
            "failedDrivingRequests",
            updatedFailedDrivingRequests
          );

          failedDrivingRequests = updatedFailedDrivingRequests;
        } catch (error) {
          console.error("Retry failed for request:", request, "Error:", error);
        }
      }
    } catch (error) {
      console.error(
        "An error occurred during retrying failed requests:",
        error
      );
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      async function fetchData() {
        const requestsInQueue = await getResponse("failedRegRequests");
        setRegRequestsInQueue(requestsInQueue?.length);
        const drivingRequestsInQueue = await getResponse(
          "failedDrivingRequests"
        );
        setDrivingRequestsInQueue(drivingRequestsInQueue?.length);
      }
      fetchData();
    }, 1000);

    return () => clearInterval(interval);
  }, [regRequestsInQueue]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isOnline && regRequestsInQueue > 0) {
        retryFailedRegRequests();
        setShowSyncedDataText(true);
      } else if (isOnline && regRequestsInQueue === 0) {
        setShowSyncedDataText(false);
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [
    regRequestsInQueue,
    isOnline,
    retryFailedRegRequests,
    showSyncedDataText,
  ]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isOnline && drivingRequestsInQueue > 0) {
        retryFailedDrivingRequests();
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [drivingRequestsInQueue, isOnline, retryFailedDrivingRequests]);

  const handleSelectedOptionChange = useCallback((option: string) => {
    setSelectedOption(option);
  }, []);

  const handleCancelDrawMode = useCallback(
    (option: string) => {
      handleSelectedOptionChange(option ?? "");
      setIsDrawMode(false);
      setDrawnObject(null);
      setHasDrawn(false);
    },
    [handleSelectedOptionChange, setDrawnObject, setHasDrawn, setIsDrawMode]
  );

  const handleDoneDrawing = useCallback(() => {
    setHasDrawn(true);
    setIsDrawMode(false);
  }, [setHasDrawn, setIsDrawMode]);

  const digiThemeUuid = useMemo(() => {
    return internalMessages?.find((obj) => obj.id === selectedBoxId)
      ?.digi_theme_uuid;
  }, [internalMessages, selectedBoxId]);

  return (
    <>
      <PageLayout orderBtnActive>
        <div
          className={`order-info ${
            isMobileView ? "mobile" : ""
          } internal-messages`}
        >
          {!isFullscreenMap && (
            <div
              className={`order-info-tasks ${isMobileView ? "small" : "large"}`}
            >
              <div className="order-info-tasks-nav-header">
                <div className="order-info-tasks-header">
                  <Text
                    text={t("common.internalMessages")}
                    size="xs"
                    fontWeight={600}
                  />
                </div>
                {!isMobileView && (
                  <div
                    className="expand-container"
                    onClick={onToggleFullscreenMap}
                  >
                    <Icon
                      name="chevron"
                      className="expand-icon"
                      flip={!isFullscreenMap}
                    />
                  </div>
                )}
              </div>

              <div className="hr" />

              <div className="order-info-tasks-filter">
                <TaskFilterButton
                  number={internalMessages?.length ?? 0}
                  text={t("common.all")}
                  selected={selectedTaskFilter === STATUSES.ALL}
                  onSelect={() => handleSetSelectedTaskFilter(STATUSES.ALL)}
                />
                {availableFilters.map((filter, index) => (
                  <TaskFilterButton
                    key={index}
                    number={
                      internalMessages?.filter((obj) => obj.status === filter)
                        .length ?? 0
                    }
                    text={t(`common.${filter}`)}
                    selected={selectedTaskFilter.includes(filter)}
                    onSelect={() => handleSetSelectedTaskFilter(filter)}
                  />
                ))}
                {isRouteSelected && (
                  <TaskFilterButton
                    number={
                      internalMessages?.filter(
                        (obj) =>
                          obj.route === parseInt(currentOrder?.route ?? "")
                      ).length ?? 0
                    }
                    text={t("common.yourRoute")}
                    selected={selectedTaskFilter === STATUSES.ROUTE}
                    onSelect={() => handleSetSelectedTaskFilter(STATUSES.ROUTE)}
                  />
                )}
              </div>

              <OrderObjectList
                tasks={
                  selectedTaskFilter === STATUSES.ALL
                    ? (internalMessages as InternalMessage[])
                    : (filteredOrderObjects as InternalMessage[])
                }
                selectedBoxId={selectedBoxId}
                needStartweight={needStartweight}
                selectedTaskFilter={selectedTaskFilter}
                handleBoxClick={handleBoxClick}
                enableDragAndDrop={false}
              />
            </div>
          )}
          {isMobileView && selectedBoxId && (
            <Modal
              show={selectedBoxId !== null}
              closeModal={() => setSelectedBoxId(undefined)}
              provideOwnContent
            >
              <MapOptions
                className="mobile"
                dataFromInternalMessages={internalMessages}
                digiThemeUuid={digiThemeUuid}
                selectedBoxId={selectedBoxId}
                onSelectedOptionChange={handleSelectedOptionChange}
                selectedOptionFromParent={selectedOption}
                clearSelectedBoxId={() => setSelectedBoxId(undefined)}
                handleCloseMessagePopup={() => handleSelectedOptionChange("")}
                showRegisterButton
                isMobileView
                hideMessage
              />
            </Modal>
          )}
          {!isMobileView && (
            <MapOptions
              className={`${
                isFullscreenMap
                  ? "collapsed-options-menu"
                  : "expanded-options-menu"
              }`}
              key={selectedBoxId}
              dataFromInternalMessages={internalMessages}
              digiThemeUuid={digiThemeUuid}
              selectedBoxId={selectedBoxId ?? selectedTblId}
              onSelectedOptionChange={handleSelectedOptionChange}
              selectedOptionFromParent={selectedOption}
              handleCloseMessagePopup={() => handleSelectedOptionChange("")}
              showRegisterButton
              hideMessage
            />
          )}
          {isDrawMode && (
            <FlipButtons
              onCancel={() => handleCancelDrawMode(selectedOption)}
              hasDrawn={hasDrawn}
              onDone={handleDoneDrawing}
            />
          )}
        </div>
      </PageLayout>
      {!isMobileView && (
        <div
          className={`order-info-map ${isFullscreenMap ? "fullscreen" : ""}`}
        >
          <MapProvider initialExtent={extent} minZoom={6} maxZoom={18}>
            <MapWrapper
              selectedId={selectedBoxId ?? selectedTblId}
              defaultExtent={extent}
              featuresWKT1={features}
              featuresWKT2={features}
              selectedOption={selectedOption}
              onFeatureMapClick={handleSetSelectedBoxId}
            >
              <div ref={mapContentRef} className="map-layout--content">
                <Outlet />
              </div>
            </MapWrapper>
          </MapProvider>
        </div>
      )}
      {isFullscreenMap && (
        <div className="collapse-container" onClick={onToggleFullscreenMap}>
          <Icon
            name="chevron"
            className="expand-icon"
            flip={!isFullscreenMap}
          />
        </div>
      )}
      <Text
        className={`connection-text ${
          isOnline ? "online-text" : "offline-text"
        }`}
        text={offlineString}
        size="xxs"
      />
    </>
  );
};

export default InternalMessages;
