import Icon from "@avinet/adaptive-ui-core/ui/Icon";
import React, { ReactNode, useCallback, useContext, useMemo } from "react";
import { NgirLogoIcon } from "../../assets/icons/NgirLogoIcon";
import { Text } from "../text/Text";
import "./PageLayout.scss";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/user-context/UserContext";
import {
  internalMessagesUrl,
  mapUrl,
  ordersOverviewUrl,
  settingsUrl,
} from "../../constants/Routes";
import { t } from "i18next";
import LOCAL_STORAGE from "../../constants/LocalStorage";
import { useOrder } from "../../context/order-context/OrderContext";
import { currentApi } from "../../utils/customer-config";
import { useMobileView } from "../../context/mobile-view-context/MobileViewContext";
import IconWithBadge from "../icon-with-badge/IconWithBadge";
import { useInternalMessages } from "../../context/internal-messages-context/InternalMessagesContext";
import { STATUSES } from "../../constants/Constants";
import { AVLogoIcon } from "../../assets/icons/AVLogoIcon";

interface PageLayoutProps {
  children: ReactNode;
  orderBtnActive?: boolean;
}

const PageLayout = ({ children }: PageLayoutProps) => {
  const { currentOrder, saveOrder } = useOrder();
  const { internalMessages } = useInternalMessages();
  const { isMobileView } = useMobileView();
  const { logoutUser } = useContext(UserContext);

  const handleLogout = async (e: React.FormEvent) => {
    e.preventDefault();
    logoutUser();
  };

  const amountOfewMessagesOnSelectedRoute = useMemo(() => {
    const selectedRoute = currentOrder?.route;
    return internalMessages?.filter(
      (message) =>
        message.route.toString() === selectedRoute &&
        message.status !== STATUSES.COMPLETED
    ).length;
  }, [internalMessages, currentOrder]);

  const navigate = useNavigate();

  const currentSelectedFilter = useMemo(() => {
    const selectedFilter = localStorage.getItem(LOCAL_STORAGE.SELECTED_FILTER);

    if (selectedFilter) {
      return selectedFilter;
    }

    return null;
  }, []);

  const handleNavigateToOrdersOverview = useCallback(() => {
    navigate(ordersOverviewUrl);
    saveOrder(null);
  }, [navigate, saveOrder]);

  const onSelectOrdersOverview = useCallback(() => {
    if (currentOrder)
      navigate(
        `${ordersOverviewUrl}/${currentOrder.id}/${currentSelectedFilter}`
      );
    else navigate(ordersOverviewUrl);
  }, [currentOrder, currentSelectedFilter, navigate]);

  const isButtonSelected = useCallback((url: string) => {
    const baseUrl = location.pathname.split("/")[1];
    return baseUrl === url.split("/")[1] ? "selected-btn" : "";
  }, []);

  const { customer } = currentApi();

  const renderIcon = () => {
    switch (customer) {
      case "NGIR":
        return <NgirLogoIcon />;
      default:
        return <AVLogoIcon />;
    }
  };

  return (
    <div className="page-layout">
      <div className="sidebar">
        <div className="logo" onClick={handleNavigateToOrdersOverview}>
          {renderIcon()}
        </div>
        <div className="menu">
          <div
            className={`menu-button ${isButtonSelected(ordersOverviewUrl)}`}
            onClick={onSelectOrdersOverview}
          >
            <Icon name="clipboardTaskList" />
            {!isMobileView && <Text text={t("common.orders")} size="xxs" />}
          </div>
          {!isMobileView && (
            <div
              className={`menu-button ${isButtonSelected(internalMessagesUrl)}`}
              onClick={() => navigate(internalMessagesUrl)}
            >
              {amountOfewMessagesOnSelectedRoute &&
              amountOfewMessagesOnSelectedRoute > 0 ? (
                <>
                  <IconWithBadge
                    icon={<Icon name="mail" />}
                    count={amountOfewMessagesOnSelectedRoute}
                  />
                  <Text text={t("common.internalMessages")} size="xxs" />
                </>
              ) : (
                <>
                  <Icon name="mail" />
                  <Text text={t("common.internalMessages")} size="xxs" />
                </>
              )}
            </div>
          )}
          {!isMobileView && (
            <div
              className={`menu-button ${isButtonSelected(mapUrl)}`}
              onClick={() => navigate(mapUrl)}
            >
              <Icon name="database" />
              <Text text={t("common.otherContent")} size="xxs" />
            </div>
          )}
          <div
            className={`menu-button ${isButtonSelected(settingsUrl)}`}
            onClick={() => navigate(settingsUrl)}
          >
            <Icon name="settings" />
            {!isMobileView && <Text text={t("common.settings")} size="xxs" />}
          </div>
          <div className="logout" onClick={handleLogout}>
            <Icon name="signOut" />
            {!isMobileView && <Text text={t("common.logout")} size="xxs" />}
          </div>
        </div>
      </div>
      <div className={`content`}>{children}</div>
    </div>
  );
};

export default PageLayout;
