import React, { useCallback, useEffect, useRef, useState } from "react";
import "./MapLayerList.scss";
import Search from "../search/Search";
import { t } from "i18next";
import { useVectorLayer } from "../../context/vector-layer-context/VectorLayerContext";
import { LoadingMask } from "../loading/LoadingMask";
import { IMapLayerObject, OfflineVectorFeat } from "../../utils/data-types";
import { getResponse } from "../../utils/indexedDb";
import { useOffline } from "../../context/offline-context/OfflineContext";
import Icon from "@avinet/adaptive-ui-core/ui/Icon";

export const MapLayerList = ({
  onToggleMapLayerList,
  showingMapLayers,
}: {
  onToggleMapLayerList: () => void;
  showingMapLayers: boolean;
}) => {
  const { isOnline } = useOffline();
  const {
    otherVectorLayers,
    handleCheckboxChange,
    activeFeatures,
    loadingFeatures,
  } = useVectorLayer();

  const ref = useRef<HTMLDivElement>(null);

  const [filteredLayers, setFilteredLayers] = useState<IMapLayerObject[]>([]);
  const [layersInDb, setLayersInDb] = useState<OfflineVectorFeat[]>([]);

  useEffect(() => {
    if (!otherVectorLayers) return;
    setFilteredLayers(otherVectorLayers);
  }, [otherVectorLayers]);

  const handleSearch = useCallback((results: IMapLayerObject[]) => {
    setFilteredLayers(results);
  }, []);

  useEffect(() => {
    const fetchLayers = async () => {
      const layers = await getResponse("vectorFeatures");
      if (layers) {
        setLayersInDb(layers);
      }
    };
    fetchLayers();
  }, []);

  const hasVectorOffline = useCallback(
    (layer: string) => {
      return layersInDb.some((l) => l.layerName === layer);
    },
    [layersInDb]
  );

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      const target = event.target as Node;
      const isToggleButton = (target as Element).closest(".btn.layers");
      if (ref.current && !ref.current.contains(target) && !isToggleButton) {
        onToggleMapLayerList();
      }
    },
    [onToggleMapLayerList]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [handleClickOutside]);

  if (!showingMapLayers) return null;

  return (
    <div
      ref={ref}
      className={`map-layer-list ${activeFeatures.length > 0 ? "tall" : ""}`}
    >
      {activeFeatures.length > 0 && (
        <div className="map-layer-list--item uncheck">
          <label htmlFor="turnOffAll">
            <input
              type="checkbox"
              id="turnOffAll"
              checked
              onChange={() => handleCheckboxChange(null)}
            />
            {t("pages.map.turnOffAllLayers")}
          </label>
        </div>
      )}
      <div className="map-layer-list--content">
        {filteredLayers?.map((layer) => (
          <div
            key={layer.name}
            className={`map-layer-list--item ${
              !hasVectorOffline(layer.name) && !isOnline ? "offline" : ""
            }`}
          >
            <label htmlFor={layer.name}>
              <input
                type="checkbox"
                id={layer.name}
                checked={activeFeatures.some((l) => l.layerName === layer.name)}
                onChange={() => handleCheckboxChange(layer)}
              />
              {layer.name}
              {loadingFeatures.has(layer.name) && isOnline && (
                <LoadingMask smallest />
              )}
              {!hasVectorOffline(layer.name) && !isOnline && (
                <Icon name="globeOffline" />
              )}
            </label>
          </div>
        ))}
      </div>
      <Search
        data={otherVectorLayers}
        onSearch={handleSearch}
        placeholder={t("pages.map.searchPlaceholder")}
      />
    </div>
  );
};

export default MapLayerList;
