import React, { useMemo, useCallback, useContext } from "react";
import { Text } from "../text/Text";
import Icon from "@avinet/adaptive-ui-core/ui/Icon";
import { t } from "i18next";
import "./Attachments.scss";
import useDataFetch from "../../hooks/useDataFetch";
import { DEFAULT_API_CONFIG } from "../../api/api-config";
import { IAttachment, IAttachmentRequest } from "../../utils/data-types";
import { LoadingMask } from "../loading/LoadingMask";
import { UserContext } from "../../context/user-context/UserContext";

interface AttachmentsProps {
  link_uuid?: string;
  showTitle?: boolean;
}

const Attachments = ({ link_uuid, showTitle }: AttachmentsProps) => {
  const { token } = useContext(UserContext);

  const attachmentsUrl = useMemo(
    () => `${DEFAULT_API_CONFIG.url}/diaryorder/attachment/get`,
    []
  );

  const attachmentsRequest = useMemo<IAttachmentRequest>(
    () => ({ link_uuid }),
    [link_uuid]
  );

  const [attachments, loading] = useDataFetch<IAttachment[]>(
    attachmentsUrl,
    "POST",
    attachmentsRequest
  );

  const currentAttachmentUrl = useCallback(
    (link_uuid: string) =>
      `${DEFAULT_API_CONFIG.url.replace(
        /\/[^/]*$/,
        ""
      )}/WebServices/generic/Media.asmx/Download?uuid=${link_uuid}&gm_session_id=${token}`,
    [token]
  );

  const handleOpenAttachment = useCallback(
    (link_uuid: string) => {
      window.open(currentAttachmentUrl(link_uuid), "_blank");
    },
    [currentAttachmentUrl]
  );

  const trimDate = useCallback((date: string) => date.split("T")[0], []);

  return (
    <div className="attachments">
      {showTitle && (
        <Text
          text={t("pages.attachments.attachments")}
          size="xs"
          fontWeight={600}
        />
      )}
      {loading ? (
        <LoadingMask small />
      ) : attachments && attachments.length > 0 ? (
        attachments.map((attachment) => (
          <div
            key={attachment.mediauuid}
            className="attachment-row"
            onClick={() => handleOpenAttachment(attachment.mediauuid)}
          >
            <div className="attachment-column">
              <Text text={trimDate(attachment.date_created)} size="xxxs" />
              <Text text={attachment.description || "-"} size="xxxs" />
            </div>
            <div className="attachment-column">
              <Text text={attachment.filename || "-"} size="xxxs" />
            </div>
            <div className="attachment-column">
              <Icon name="chevron" />
            </div>
          </div>
        ))
      ) : (
        <Text text={t("pages.attachments.noAttachments")} size="xxxs" />
      )}
    </div>
  );
};

export default Attachments;
