import React, { useCallback, useRef, useState } from "react";
import { TextButton } from "../buttons/TextButton";
import "./ImageUploader.scss";
import { Icon } from "../icon/Icon";
import { t } from "i18next";

interface ImageUploaderProps {
  onImageSelected: (
    base64: string,
    imageSrc: string,
    imageExtension: string,
    fileName: string
  ) => void;
  closeModal: () => void;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({
  onImageSelected,
  closeModal,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [isCameraActive, setIsCameraActive] = useState<boolean>(false);
  const [imageBase64, setImageBase64] = useState<string | undefined>();
  const [imageExtension, setImageExtension] = useState<string | undefined>();
  const [fileName, setFileName] = useState<string | undefined>();
  const [cameraOption, setCameraOption] = useState<"user" | "environment">(
    "environment"
  );

  const switchCamera = () => {
    const newCamera = cameraOption === "user" ? "environment" : "user";
    setCameraOption(newCamera);
    startCam(newCamera);
  };

  const startCam = async (facingMode: "user" | "environment") => {
    setImageSrc(null);
    try {
      setIsCameraActive(true);
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: facingMode },
      });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }
    } catch (error) {
      console.error("Error accessing webcam:", error);
    }
  };

  const captureImage = () => {
    if (canvasRef.current && videoRef.current) {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");

      if (context) {
        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;
        context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

        const base64Image = canvas.toDataURL("image/png");
        setImageSrc(base64Image);
        setIsCameraActive(false);
      }
    }
  };

  const handleAddPictureClick = () => {
    setImageSrc(null);
    fileInputRef.current?.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const result = e.target?.result as string;
        setImageSrc(result);
        setImageBase64(result.split(",")[1] || ""); // Base64 data without prefix
        setImageExtension(file.type.split("/")[1]);
        setFileName(file.name);
      };
      reader.readAsDataURL(file);
    }
  };

  const confirmImage = useCallback(() => {
    if (imageBase64 && imageExtension && imageSrc && fileName) {
      closeModal();
      onImageSelected(imageBase64, imageSrc, imageExtension, fileName);
    } else if (imageSrc) {
      const base64 = imageSrc.split(",")[1];
      const extension = imageSrc.split(/[/:;]/)[2];
      const name = imageSrc.split(/[/:;]/)[1];
      closeModal();
      onImageSelected(base64, imageSrc, extension, name + "." + extension);
    }
  }, [
    imageExtension,
    imageBase64,
    fileName,
    imageSrc,
    closeModal,
    onImageSelected,
  ]);

  const setInitialModalState = useCallback(() => {
    setImageSrc(null);
    setImageBase64(undefined);
    setImageExtension(undefined);
    setFileName(undefined);
    setIsCameraActive(false);
  }, []);

  return (
    <div className="image-uploader">
      {isCameraActive && (
        <>
          <button className="cam-button" onClick={switchCamera}>
            <Icon name="cameraSwitch" />
          </button>
          <video ref={videoRef} />
        </>
      )}

      <canvas ref={canvasRef} style={{ display: "none" }} />

      {imageSrc && (
        <div>
          <img src={imageSrc} alt="Preview" />
        </div>
      )}

      {!imageSrc && !isCameraActive && (
        <div className="initial-buttons">
          <TextButton
            text={t("pages.message.uploadImage.takePicture")}
            onClick={() => startCam("environment")}
            icon={<Icon name="camera" />}
            invertColors
          />
          <TextButton
            text={t("pages.message.uploadImage.upload")}
            onClick={handleAddPictureClick}
            icon={<Icon name="cameraAdd" />}
            invertColors
          />
        </div>
      )}

      <div className="buttons">
        {(isCameraActive || imageSrc) && (
          <TextButton
            text={t("pages.message.uploadImage.back")}
            onClick={setInitialModalState}
            invertColors
          />
        )}
        {imageSrc && !fileName && (
          <div className="picture-taken">
            <TextButton
              text={t("pages.message.uploadImage.takeNew")}
              onClick={() => startCam(cameraOption)}
              invertColors
            />
            <TextButton
              text={t("pages.message.uploadImage.confirm")}
              onClick={confirmImage}
            />
          </div>
        )}
        {imageSrc && fileName && (
          <TextButton
            text={t("pages.message.uploadImage.confirm")}
            onClick={confirmImage}
          />
        )}

        {isCameraActive && (
          <button className="cam-button" onClick={captureImage}>
            <Icon name="cameraSparkles" />
          </button>
        )}
        {fileInputRef && (
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            accept=".png,.jpg,.jpeg"
            onChange={handleFileChange}
          />
        )}
      </div>
    </div>
  );
};

export default ImageUploader;
