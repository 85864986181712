import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Text } from "../text/Text";
import "./Log.scss";
import { t } from "i18next";
import superagent from "superagent";
import { DEFAULT_API_CONFIG } from "../../api/api-config";
import Icon from "@avinet/adaptive-ui-core/ui/Icon";
import { DiaryDetails } from "../../utils/data-types";
import Attachments from "../attachments/Attachments";
import LogDetails from "./log-details/LogDetails";
import { LoadingMask } from "../loading/LoadingMask";
import parse from "html-react-parser";
import TaskFilterButton from "../buttons/TaskFilterButton";
import { LOG_BUTTONS } from "../../constants/Constants";
import { UserContext } from "../../context/user-context/UserContext";
import { formatDate } from "../../utils/formate-date";
import { selectedLanguage } from "../../constants/Language";

const Log = ({
  className,
  diarydetailsRequest,
  orderobjectFkUuid,
  onClose,
}: {
  className?: string;
  diarydetailsRequest: Record<string, unknown>;
  orderobjectFkUuid: string | undefined;
  onClose: () => void;
}) => {
  const [listView, setListView] = useState<boolean>(true);
  const [selectedLogEvent, setSelectedLogEvent] = useState<DiaryDetails | null>(
    null
  );
  const [selectedLogFilter, setSelectedLogFilter] = useState<string>(
    LOG_BUTTONS.RELEVANT
  );
  const [logResponseAll, setLogResponseAll] = useState<DiaryDetails[]>([]);
  const [logResponseFiltered, setLogResponseFiltered] = useState<
    DiaryDetails[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useContext(UserContext);

  useEffect(() => {
    setListView(true);
  }, [orderobjectFkUuid]);

  const handleSetView = useCallback(() => {
    setListView((prev) => !prev);
  }, []);

  const handleEventClick = useCallback(
    (item: DiaryDetails) => {
      setSelectedLogEvent(item);
      handleSetView();
    },
    [handleSetView]
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { show_all, ...filteredLogRequest } = diarydetailsRequest;

  const logUrl = useMemo<string>(
    () => DEFAULT_API_CONFIG.url + "/diaryorder/diarydetailV2/getDiaryDetails",
    []
  );

  const fetchAllLogPosts = useCallback(async () => {
    if (!token) return;

    setIsLoading(true);

    try {
      const data = await superagent
        .post(logUrl)
        .send(diarydetailsRequest)
        .set("Accept", "application/json")
        .set("gm_session_id", token);

      setLogResponseAll(data.body);
    } catch (error) {
      console.error("Error fetching log posts:", error);
    } finally {
      setIsLoading(false);
    }
  }, [diarydetailsRequest, logUrl, token]);

  useEffect(() => {
    fetchAllLogPosts();
    // Remove the dependency array to avoid infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchFilteredLogPosts = useCallback(async () => {
    if (!token) return;

    setIsLoading(true);

    try {
      const data = await superagent
        .post(logUrl)
        .send(filteredLogRequest)
        .set("Accept", "application/json")
        .set("gm_session_id", token);

      setLogResponseFiltered(data.body);
    } catch (error) {
      console.error("Error fetching log posts:", error);
    } finally {
      setIsLoading(false);
    }
  }, [token, logUrl, filteredLogRequest]);

  useEffect(() => {
    fetchFilteredLogPosts();
    // Remove the dependency array to avoid infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isMatchingLogUuid = useCallback(
    (uuid: string) => {
      return orderobjectFkUuid === uuid ? "matching" : "";
    },
    [orderobjectFkUuid]
  );

  const handleFilter = useCallback(
    (filter: string) => () => {
      setSelectedLogFilter(filter);
    },
    []
  );

  const hasData = useMemo(() => {
    return logResponseFiltered.length > 0 || logResponseAll.length > 0;
  }, [logResponseAll, logResponseFiltered]);

  const dataToDisplay = useMemo(() => {
    return selectedLogFilter === LOG_BUTTONS.ALL
      ? logResponseAll
      : logResponseFiltered;
  }, [logResponseAll, logResponseFiltered, selectedLogFilter]);

  return (
    <>
      {listView ? (
        <div className={`log ${className}`}>
          <div className="log-header">
            <div className="log-header-buttons">
              <Text
                className="log-title"
                text={t("pages.log.title")}
                size="xxs"
                fontWeight={600}
              />
              <TaskFilterButton
                number={logResponseAll.length}
                text={t(`pages.log.${LOG_BUTTONS.ALL}`)}
                selected={selectedLogFilter === LOG_BUTTONS.ALL}
                onSelect={handleFilter(LOG_BUTTONS.ALL)}
                smaller
              />
              <TaskFilterButton
                number={logResponseFiltered.length}
                text={t(`pages.log.${LOG_BUTTONS.RELEVANT}`)}
                selected={selectedLogFilter === LOG_BUTTONS.RELEVANT}
                onSelect={handleFilter(LOG_BUTTONS.RELEVANT)}
                smaller
              />
            </div>

            <div className="log-header-close-btn" onClick={onClose}>
              <Icon name="cross" />
            </div>
          </div>
          {isLoading ? (
            <LoadingMask small />
          ) : (
            hasData &&
            !isLoading &&
            dataToDisplay.map((item: DiaryDetails, index: number) => (
              <div
                className={"log-row"}
                key={index}
                onClick={() => handleEventClick(item)}
              >
                <div className="log-column" key={index}>
                  <Text
                    className={`${isMatchingLogUuid(item.uuid)}`}
                    text={
                      formatDate(item.date, selectedLanguage, t) +
                      " - " +
                      item.status +
                      " - " +
                      item.codedesc
                    }
                    size="xxxs"
                    fontWeight={600}
                  />
                  <Text
                    className="log-column-value"
                    text={
                      item.comment === "" || item.comment === null
                        ? "-"
                        : (parse(item.comment) as string)
                    }
                    size="xxxs"
                  />{" "}
                </div>
                <div className="icon-row">
                  {item.attachments > 0 && (
                    <Icon className="attach-icon" name="attach" />
                  )}
                  <Icon name="chevron" />
                </div>
              </div>
            ))
          )}
        </div>
      ) : (
        <div className={`log ${className}`}>
          <div className="log-header">
            <div className="arrow-container" onClick={handleSetView}>
              <Icon className="arrow-icon" name="arrow" flip />
              <Text
                className="log-title"
                text={t("pages.log.title")}
                size="xxxs"
              />
            </div>
            <div className="log-header-close-btn" onClick={onClose}>
              <Icon name="cross" />
            </div>
          </div>
          {selectedLogEvent && (
            <div className="log-column">
              <Text
                className={`${isMatchingLogUuid(selectedLogEvent.uuid)}`}
                text={
                  formatDate(selectedLogEvent.date, selectedLanguage, t) +
                  " - " +
                  selectedLogEvent.status +
                  " - " +
                  selectedLogEvent.codedesc
                }
                size="xxxs"
                fontWeight={600}
              />
              <LogDetails details={selectedLogEvent.details} />
              <Text
                className="log-column-value"
                text={t("pages.log.comment")}
                size="xs"
                fontWeight={600}
              />
              <Text
                className="log-column-value"
                text={
                  selectedLogEvent.comment === "" ||
                  selectedLogEvent.comment === null
                    ? "-"
                    : (parse(selectedLogEvent.comment) as string)
                }
                size="xxxs"
              />
            </div>
          )}
          <Attachments showTitle link_uuid={selectedLogEvent?.uuid} />
        </div>
      )}
    </>
  );
};

export default Log;
