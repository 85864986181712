// Parts of this from https://github.com/datalyze-solutions/globalmaptiles/blob/master/globalmaptiles.js

const pi_2 = Math.PI * 2;
const tileSize = 256;
const initialResolution = (pi_2 * 6378137) / tileSize;
const originShift = (pi_2 * 6378137) / 2.0;

export function MercatorCoordToTile(
  mx: number,
  my: number,
  zoom: number
): { tx: number; ty: number } {
  const pixels = MetersToPixels(mx, my, zoom);
  return PixelsToTile(pixels.px, pixels.py, zoom);
}

function MetersToPixels(
  mx: number,
  my: number,
  zoom: number
): { px: number; py: number } {
  // Converts EPSG:900913 to pyramid pixel coordinates in given zoom level
  const res = Resolution(zoom);
  const px = (mx + originShift) / res;
  const py = (my + originShift) / res;
  return { px: px, py: py };
}

function Resolution(zoom: number): number {
  // Resolution (meters/pixel) for given zoom level (measured at Equator)
  return initialResolution / Math.pow(2, zoom);
}

function PixelsToTile(px: number, py: number, zoom: number) {
  // Tiles are indexed from the top-left corner in Web Mercator
  const tx = Math.round(Math.ceil(px / tileSize) - 1);
  const ty = Math.round(Math.ceil(py / tileSize) - 1);

  // Flip Y-axis to match TMS (Tile Map Service) convention
  const ty_flipped = (1 << zoom) - 1 - ty;

  return { tx: tx, ty: ty_flipped };
}

export function TileToMercatorCoord(
  tx: number,
  ty: number,
  zoom: number
): { mx: number; my: number } {
  const pixels = TileToPixels(tx, ty);
  const flippedPy = (1 << zoom) * tileSize - pixels.py - tileSize / 2;
  return PixelsToMeters(pixels.px + tileSize / 2, flippedPy, zoom);
}

function TileToPixels(tx: number, ty: number): { px: number; py: number } {
  const px = tx * tileSize;
  const py = ty * tileSize;
  return { px: px, py: py };
}

function PixelsToMeters(
  px: number,
  py: number,
  zoom: number
): { mx: number; my: number } {
  const res = Resolution(zoom);
  const mx = px * res - originShift;
  const my = py * res - originShift;
  return { mx: mx, my: my };
}
